import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Institutions, InstitutionId, InstitutionApplication } from '../pages/institutions';

const InstitutionRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Institutions />} />
        <Route path=':id' element={<InstitutionId />} />
        <Route path=':id/:appId' element={<InstitutionApplication />} />
    </Routes>
);

export default InstitutionRoutes;