import { FunctionComponent, useState } from 'react';
import { TextInput, Checkbox } from '../components/inputs';
import { Link, useNavigate } from 'react-router-dom';
import RadialGradient from '../components/RadialGradient';
import Button from '../components/Button';
import { GetCurrentUserOutput } from 'aws-amplify/auth';
import { useUser } from '../hooks/useUser';


const Login: FunctionComponent = () => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const { login } = useUser();
    const navigate = useNavigate();

    const submit = async (event: any) => {
        event.preventDefault()
        try {
            const result: boolean = await login(email, password);
            if (result) navigate('/dashboard/clients');
        } catch (error) {
            setError('Failed To Login')
        }
    }

    return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className='w-[500px] bg-white rounded-lg border-primary border-2 absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Enter Login
                    </h4>
                </div>
                {
                    error && <p className='text-danger'>{error}</p>
                }
                <form className='py-4 px-8 flex flex-col'>
                    <TextInput
                        label='Email'
                        id='email'
                        placeholder='Enter email...'
                        onChange={(emailInput) => setEmail(emailInput)}
                        containerStyles='mb-4'
                    />

                    <TextInput
                        label='Password'
                        id='password'
                        password
                        placeholder='Enter password...'
                        onChange={(passwordInput) => setPassword(passwordInput)}
                        containerStyles='mb-4'
                    />

                    <Checkbox
                        labelText='Remember Me'
                        checked={rememberMe}
                        onChange={(event) => setRememberMe(event.target.checked)}
                        labelStyles='ml-3'
                        containerStyles='mb-4'
                    />

                    <Button text='Login' onClick={submit} styles='self-center w-[50%] mb-8' />
                    
                    <Link to='/forgot-password' className='text-primary self-center'>
                        Forgot Password
                    </Link>
                </form>
            </div>  
        </div>
    )
}

export default Login;