import { FunctionComponent, ReactNode } from "react";

interface IWideTileProps {
    children: ReactNode;
    styles?: string;
}

const WideTile: FunctionComponent<IWideTileProps> = ({ children, styles }) => (
    <div data-testid='wide-tile' className={`w-full shadow-lg p-4 ${styles}`}>
        {children}
     </div>
);

export default WideTile;