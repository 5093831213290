import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addCreditUser } from "../../utils/SoftPullAPI";
import { TextInput, Checkbox } from "../inputs";
import { IExpertInvitationForm } from "../../constants/experts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addExpert, formatExpert, updateExpert } from "../../utils/ClientVitalsAPI";
import { FunctionComponent, MouseEvent, useState } from "react";
import { confirmUser, registerAuthUser, markEmailAsVerified } from "../../utils/Cognito";
import ExpertInvitationSchema from "./ValidationScehma/ExpertInvitationSchema";
import DashboardPageContainer from "../DashboardPageContainer";
import PageTitle from "../PageTitle";
import convert from 'xml-js';
import Button from "../Button";
import axios from "axios";

const ExpertInvitationForm: FunctionComponent = () => {
    const [error, setError] = useState<string | null>(null);
    const [terms, setTerms] = useState<boolean>(false);
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const institution = params.get('fi') || '';
    const baseName = params.get('name')?.replaceAll('-', ' ') || '';
    const email = params.get('email') || '';

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: baseName || '',
            email: email || '',
            institutionId: institution || '',
            password: ''
        },
        resolver: yupResolver(ExpertInvitationSchema)
    });

    const submit = async (values: IExpertInvitationForm) => {
        try {
            const authResult = await registerAuthUser(values.email, values.password);
            await confirmUser(values.email);
            await markEmailAsVerified(values.email);

            if (authResult?.UserSub) {
                const newExpert = formatExpert(values, institution);
                newExpert.userId = authResult?.UserSub;

                const result = await addExpert(newExpert);
                const ipResult = await axios.get('https://api.ipify.org?format=json');

                const cbcResult = await addCreditUser({
                    uid: result.data.result.expertId,
                    name: newExpert.userInfo.name,
                    email: newExpert.userInfo.email,
                    password: values.password,
                    ip: ipResult.data.ip
                });

                const result2 = convert.xml2js(cbcResult, { compact: true });

                newExpert.userId = result.data.result.expertId;
                //@ts-ignore
                newExpert.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
    
                await updateExpert(newExpert);

                navigate('/login');
            }
        } catch (error) {
            setError('Failed To Submit Form');
        }
    }

    const submissionSwitch = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        
        return handleSubmit(submit)();
    }

    return (
        <DashboardPageContainer>
            <PageTitle title="Account Registration" />

            {(errors.institutionId?.message || institution.length < 1) && <h4 className="text-red-500">{errors.institutionId?.message}</h4>}
            {
                error && <p className='text-danger'>{error}</p>
            }
            <form className="max-w-screen p-8 flex flex-col justify-center align-center">
                <div className="w-full flex flex-wrap justify-around">
                <TextInput 
                    id='name'
                    label='name'
                    placeholder='Name...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.name?.message}
                />
                <TextInput 
                    id='email'
                    label='email'
                    placeholder='Email...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.email?.message}            
                />
                <TextInput 
                    id='password'
                    label='password'
                    placeholder='Password...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    password
                    error={errors.password?.message}   
                />
                <TextInput 
                    id='address'
                    label='address'
                    placeholder='Address...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'            
                    error={errors.address?.message}   
                />
                <TextInput 
                    id='city'
                    label='city'
                    placeholder='City...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.city?.message}               
                />
                <TextInput 
                    id='state'
                    label='state'
                    placeholder='State...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.state?.message}           
                />
                <TextInput 
                    id='zip'
                    label='zip'
                    placeholder='Postal Code...'
                    register={register}
                    error={errors.zip?.message}   
                    containerStyles='my-0 p-0 w-[30%]'
                />
                <TextInput 
                    id='phone'
                    label='phone'
                    placeholder='Phone...'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.phone?.message}   
                />   
                <TextInput 
                    id='birthdate'
                    label='Birthdate'
                    placeholder='MM/DD/YYYY'
                    register={register}
                    containerStyles='my-0 p-0 w-[30%]'
                    error={errors.birthdate?.message}
                />
                </div>       

                <Checkbox
                    labelText='Terms & Conditions'
                    checked={terms}
                    onChange={(event) => setTerms(event.target.checked)}
                    labelStyles='ml-3'
                    containerStyles='my-4'
                    terms
                />
                
                <Button
                    text={'Submit'}
                    onClick={submissionSwitch}
                    styles='w-[300px] mt-4 mx-auto'
                />
            </form>
        </DashboardPageContainer>
    )
}

export default ExpertInvitationForm;