import { FunctionComponent } from "react";
import { TextInput } from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { useUser } from "../../../hooks/useUser";
import { referClient } from "../../../utils/ClientVitalsAPI";
import Button from "../../Button";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";

interface IReferClientFormProps {
    onCancel: () => void;
} 

interface IReferClientForm {
    name: string;
    email: string;
    phone: string;
    address: string;
    notes: string;
}

const ReferClientForm: FunctionComponent<IReferClientFormProps> = ({ onCancel }) => {
    const { user } = useUser();
    const { updateNotification } = useDashboard();
    const { register, handleSubmit } = useForm<IReferClientForm>({
        defaultValues: {
            email: '',
            name: '',
            phone: '',
            address: '',
            notes: ''
        }
    });

    const submit = async (values: { email: string, name: string, phone: string, address: string, notes: string }) => {
        const payload = {
            email: values.email,
            name: values.name,
            phone: values.phone,
            address: values.address,
            notes: values.notes,
            referrer: user.userInfo?.name || ''
        }

        try {
            const result = await referClient(payload);

            if (result.status === 202) {
                updateNotification({
                    message: 'Client Referral Sent',
                    theme: NotificationThemes.SUCCESS
                });
                onCancel();
            }
        } catch (error) {
                updateNotification({
                    message: 'Client Invite Referral To Send',
                    theme: NotificationThemes.FAIL
                });
                onCancel();
        }
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-4">
                <TextInput
                    label="Name"
                    id="name"
                    placeholder="Enter client name..."
                    register={register}
                />

                <TextInput
                    label="Email"
                    id="email"
                    placeholder="Enter client email..."
                    register={register}
                />

                <TextInput
                    label="Phone"
                    id="phone"
                    placeholder="Enter client phone number..."
                    register={register}
                />

                <TextInput
                    label="Address"
                    id="address"
                    placeholder="Enter client address..."
                    register={register}
                />

                <TextInput
                    label="Notes"
                    id="notes"
                    placeholder="Enter any notes on the client..."
                    register={register}
                    containerStyles="col-span-2"
                />
            </div>

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </div>
    )
}

export default ReferClientForm;