import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface ITableRowProps {
    rowSelected: boolean;
    itemStrings: string[]
    linkTo?: string;
}

const TableRow: FunctionComponent<ITableRowProps> = ({ itemStrings, rowSelected, linkTo }) => (
    <tr>
        {
            itemStrings.map((item: string) => (
                <td
                    key={Math.random()}
                    className={`px-4 h-[35px]text-sm border border-secondary-light ${rowSelected ? 'bg-secondary-light' : 'bg-white' }`}
                >
                    {
                        (linkTo && typeof item === 'string')
                            ? <Link to={linkTo}>{item}</Link>
                            : <>{item}</>
                   }
                </td>
            ))
        }
    </tr>
);

export default TableRow;