import { FunctionComponent, useState } from "react";
import { BUTTON_THEMES } from "../Button";
import Button from "../Button";
import Modal from "./Modal";
import { TextInput } from "../inputs";
import { CreditUnions } from "../../utils/SoftPullAPI";
import Dropdown from "../inputs/Dropdown";


interface IConfirmPullModalProps {
    pullCredit: Function;
    creditReportType: CreditUnions;
    setCreditReportType: Function;
}

const ConfirmPullModal: FunctionComponent<IConfirmPullModalProps> = ({ pullCredit, creditReportType, setCreditReportType }) => {
    const [open, setOpen] = useState<boolean>(false);
    
    const [password, setPassword] = useState('');

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Pull Credit" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Confirm Credit Pull"
                content={
                    <div className="w-[100%] flex flex-col items-center justify-between mt-3">
                        <p>This will charge the owner account for a {creditReportType === CreditUnions.TRI_MERGE ? 'Tri-Merge' : 'Single'} credit pull.</p>

                        <p className="mt-4">Select Credit Union and Enter Password:</p>

                        <div className="w-[100%] max-w-screen flex justify-center items-center my-4">
                            <form className="width-[100%]">
                                <Dropdown
                                    id="creditReportType"
                                    currentValue={creditReportType}
                                    onChange={setCreditReportType}
                                    options={[
                                        { value: CreditUnions.CLARITY_SERVICES, label: CreditUnions.CLARITY_SERVICES },
                                        { value: CreditUnions.EQUIFAX, label: CreditUnions.EQUIFAX },
                                        { value: CreditUnions.EXPERIAN, label: CreditUnions.EXPERIAN },
                                        { value: CreditUnions.TRANS_UNION, label: CreditUnions.TRANS_UNION },
                                        { value: CreditUnions.TRI_MERGE, label: "Tri-Merge" },
                                    ]}
                                />
                            </form>
                        </div>

                        <TextInput
                            id='password'
                            placeholder="Enter password..."
                            onChange={(value) => setPassword(value)}
                            password
                        />
                        
                        <div>
                            <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={toggleModal} styles="mr-4" />
                            <Button text="Confirm" onClick={async () => {
                                pullCredit(password);
                                toggleModal();
                            }} />
                        </div>
                    </div>
                }
                onClose={toggleModal} 
            />
        </div>
    )
}

export default ConfirmPullModal;