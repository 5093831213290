import { FunctionComponent } from "react";
import { ManagedClientUser } from "../../constants/clients";
import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';

interface IClientInfoTileProps {
    toggleForm: () => void;
    client: ManagedClientUser
}

const ClientInfoTile: FunctionComponent<IClientInfoTileProps> = ({client, toggleForm}) => (
    <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">Client Information</h4>
            <p className="mt-2">
                <EditIcon
                    height={25}
                    width={25}
                    onClick={toggleForm}
                    className="cursor-pointer"
                    data-testid='edit-client-icon'
                />
            </p>
        </div>
        <div className="text-md flex flex-col align-center">
            <p className="mb-3"><span className="font-medium">Name:</span> {client?.userInfo?.name}</p>
            <p className="mb-3"><span className="font-medium">Email:</span> {client?.userInfo?.email}</p>
            <p className="mb-3"><span className="font-medium">Phone:</span> {client?.userInfo?.phone}</p>
            <p className="mb-3"><span className="font-medium">DoB:</span> {client?.userInfo?.dob}</p>

            <p className="mb-3"><span className="font-medium">Address:</span> {client?.userInfo?.address}</p>
            <p className="mb-3"><span className="font-medium">City:</span> {client?.userInfo?.city}</p>
            <p className="mb-3"><span className="font-medium">State:</span> {client?.userInfo?.state}</p>
            <p className="mb-3"><span className="font-medium">Postal Code:</span> {client?.userInfo?.zip}</p>
            <p className="mb-3"><span className="font-medium">Account Creation:</span> {client?.metadata?.createdAt}</p>
            <p className="mb-3"><span className="font-medium">Last Update:</span> {client?.metadata?.updatedAt}</p>
            {client?.metadata.invitedBy && <p className="mb-3"><span className="font-medium">Invited By:</span> {client?.metadata?.invitedBy}</p>}
        </div>
    </>
);
export default ClientInfoTile;