import { FunctionComponent, useState } from "react";
import { clientTableActions } from "../../components/actionMenu/types";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import { InviteClientModal } from "../../components/modals";
import PageTitle from "../../components/PageTitle";
import useClients from "../../hooks/useClients";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import ReferClientModal from "../../components/modals/ReferClientModal";
import useExperts from "../../hooks/useExperts";
import { useUser } from "../../hooks/useUser";
import Dropdown from "../../components/inputs/Dropdown";
import { ACCESS_LEVEL } from "../../constants";

const Clients: FunctionComponent = () => {
    const { user } = useUser();
    const { metadata } = user;
    const { accessLevel } = metadata;
    const { clients, loading } = useClients();
    const { experts } = useExperts();
    
    const [selectedExpert, setSelectedExpert] = useState<string>('Choose An Individual');
     
    const headerList = ['checkbox', 'name', 'email', 'phone', 'city', 'state', 'user', 'actions']
    const formattedClients = clients?.map((client) => {
        return {
            userId: client.userId,
            name: client.userInfo.name,
            email: client.userInfo.email,
            phone: client.userInfo.phone,
            city: client.userInfo.city,
            state: client.userInfo.state,
            user: experts.find(({userId}) => userId === client.userInfo.expertId)?.userInfo?.name || "N/A",
            expertId: client.userInfo.expertId
        }
    });

    const ExpertDropdown: FunctionComponent = () => (
        <div className="mr-4">
            <Dropdown
                id="expert"
                defaultText="Choose An Individual"
                currentValue={selectedExpert}
                onChange={setSelectedExpert}
                options={experts?.map(({userId, userInfo}) => {
                    return { value: userId, label: userInfo?.name }
                })}
            />
        </div>
    )

    const CustomButtons: FunctionComponent = () => (
        <div className="flex justify-between items-center">
            {accessLevel <= ACCESS_LEVEL.CVA ? <ExpertDropdown /> : <></>}
            <InviteClientModal />
            <ReferClientModal />
        </div>
    );

    return (
        <DashboardPageContainer>
            <PageTitle title="clients" />

            {
                loading ? <Loading />
                        : <Table
                            columnHeaders={headerList}
                            records={selectedExpert !== 'Choose An Individual' ? formattedClients.filter((record) => record.expertId === selectedExpert) : formattedClients}
                            tableActions={clientTableActions}
                            customButton={<CustomButtons />}
                            linkBase="/dashboard/clients"
                        />
            }
        </DashboardPageContainer>
    );
}

export default Clients;