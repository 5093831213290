import { FunctionComponent, ChangeEvent } from "react";
import { TextInput, Checkbox } from "../inputs";
import { UseFormRegister } from "react-hook-form";
import RadioGroup from "../inputs/RadioGroup";
import { UserTypes } from "../../constants";

interface IRegisterInstitutionFormProps {
    register: UseFormRegister<any>
    terms: boolean
    accountType: string;
    setTerms: (event: ChangeEvent<HTMLInputElement>) => void
    errors?: any
}

const RegisterInstitutionForm: FunctionComponent<IRegisterInstitutionFormProps> = ({ register, setTerms, terms, errors, accountType }) => {
    return (
        <>
            <p className="ml-3 text-gray-700 text-sm font-bold">Account Type:</p>
            <RadioGroup 
                register={register}
                selectedOption={accountType}
                name="accountType"
                containerStyles="flex justify-around items-end flex-wrap mb-4"
                options={[
                    { id: UserTypes.CONSULT_REFERRALS, label: 'Referral'},
                    { id: UserTypes.FINANCIAL_EXPERT, label: 'Individual'},
                    { id: UserTypes.FINANCIAL_INSTITUTION, label: 'Institution'},
                ]}
            />
            <div className="w-full flex flex-wrap justify-around">
            <TextInput
                label='Email'
                id='email'
                placeholder='Contact email...'
                containerStyles='w-[45%]'
                testId='register-institution-email-input'
                register={register}
                error={errors.email?.message}
            />
    
    
            <TextInput
                label='Password'
                id='password'
                password
                placeholder='Enter Password...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-password-input'
                error={errors.password?.message}
            />
    
            <TextInput
                label='Name'
                id='name'
                placeholder='Company name...'
                register={register}
                key='company'
                containerStyles='w-[45%]'
                testId='register-institution-company-input'
                error={errors.name?.message}
            />
    
            <TextInput
                label='Phone'
                id='phone'
                placeholder='Contact phone number...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-phone-input'
                error={errors.phone?.message}
            />
    
            <TextInput
                label='Address'
                id='address'
                placeholder='Street address...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-address-input'
                error={errors.address?.message}
            />
    
            <TextInput
                label='City'
                id='city'
                placeholder='City...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-city-input'
                error={errors.city?.message}
            />
    
            <TextInput
                label='State'
                id='state'
                placeholder='State...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-state-input'
                error={errors.state?.message}
            />
    
            <TextInput
                label='Zip'
                id='postal_code'
                placeholder='Postal code...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-zip-input'
                error={errors.zip?.message}
            />
    
            <TextInput
                label='Referred By (Optional)'
                id='referredBy'
                placeholder="Referrer's name..."
                register={register}
                containerStyles='w-[45%]'
                testId='register-institution-zip-input'
            />
            </div>
    
            <Checkbox
                labelText='Terms & Conditions'
                checked={terms}
                onChange={setTerms}
                labelStyles='ml-3'
                containerStyles='my-4'
                terms
            />
    
            <div className="w-[100%]" />
        </>
    );
}

export default RegisterInstitutionForm;