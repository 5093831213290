import * as yup from "yup";

const EditExpertSchema = yup.object().shape({
    name: yup
        .string(),
    address: yup
        .string(),
    city: yup
        .string(),
    state: yup
        .string(),
    zip: yup
        .string(),
    dob: yup
        .string(),   
    phone: yup
        .string(),
    admin: yup
        .array()
});

export default EditExpertSchema;