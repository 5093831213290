import { FunctionComponent } from "react";


interface IStepCircleProps {
    step: string | number;
    completed: boolean;
}

const StepCircle: FunctionComponent<IStepCircleProps> = ({ step, completed }) => (
    <div className={`w-[40px] h-[40px] rounded-full flex justify-center items-center text-white ${completed ? 'bg-primary' : 'bg-secondary-dark'}`}>
        <p>{step}</p>
    </div>
);

export default StepCircle;