import React, { FunctionComponent } from "react";
import Button, { BUTTON_THEMES } from "../../Button";
import { TextInput } from "../../inputs";

interface ICreditPullFormInfoProps {
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    submit: () => void;
    setCount: (value: string) => void;
}

const CreditPullFormInfo: FunctionComponent<ICreditPullFormInfoProps> = ({onCancel, submit, setCount}) => {

    return (
        <div>
            <TextInput 
                id='applicantCount'
                placeholder="1"
                label="Number of Applicants (including primary)"
                onChange={setCount}
            />
            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={submit} />
            </div>
        </div>
    )
}

export default CreditPullFormInfo;