import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { InstitutionAuthRegisterForm, InstitutionUser } from "../../constants/institutions";
import { ACCESS_LEVEL, ApplicationTypes, UserTypes } from "../../constants";
import { IClientInvitationForm, ManagedClientUser } from "../../constants/clients";
import { ExpertUser, IExpertInvitationForm } from "../../constants/experts";
import { AdminUser, IAddAdminForm } from "../../constants/admins";
import { PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../../constants/enums";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

export const getCurrentUserData = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/users/${id}`);
        
        return result?.data?.data?.Items[0]
    } catch (error) {
        throw error;
    }
}

// Institution Helpers
export const newInstituteApplication: (data: InstitutionAuthRegisterForm, userId: string) => Object = async (data, userId) => {
    const institutionApplication = generateInstitutionApplication(data, userId);

    try {
        const result = await axios.post(`${BASE_URL}/institutions/apply`, institutionApplication);
   
        return result;
    } catch (error) {
        throw error;
    }
}

export const getInstitutionApplications = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/institutions/admin-applications`);
  
        return result?.data?.result?.Items;
    } catch (error) {
        throw error;
    }   
}

export const getInstitutions = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/institutions/all`);
  
        return result?.data?.result?.Items;
    } catch (error) {
        throw error;
    }   
}

export const getReferralAccounts = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/referral-accounts/all`);
  
        return result?.data?.result?.Items;
    } catch (error) {
        throw error;
    }   
}

export const getInstitutionApplicationById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/institutions/admin-applications/${id}`);
    
        return result?.data?.result?.Items[0];
    } catch (error) {
        throw error;
    }   
}

export const approveInstitutionApplication = async (institution: InstitutionUser) => {
    try {
        const result = await axios.post(`${BASE_URL}/institutions/approve`, institution);

        return result
    } catch (error) {
        throw error;
    }
}

export const getInstitutionUser = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/users/${id}`);
   
        return result?.data?.data?.Items[0]
    } catch (error) {
        throw error;
    }
}

export const getInstitutionClients = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/institution-clients/${id}`);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const generateInstitutionApplication: (data: InstitutionAuthRegisterForm, userId: string) => Record<any, any> = (data, userId) => {
    return {
        applicationId: userId,
        applicationType: data.accountType,
        applicationFormData: {
            name: data.name,
            address: data.address,
            city: data.city,
            state: data.state,
            country: data.country,
            zip: data.zip,
            phone: data.phone,
            email: data.email,
            ein: data.ein,
            reasonForApplication: data.reasonForApplication,
            refferedBy: data.referredBy,
            agreedToTerms: data.agreedToTerms,
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            applicationStart: new Date().toLocaleString(), // TODO: Update at start of application
            applicationSubmit: new Date().toLocaleString(),
            browserUsed: 'chrome', //TODO: update to be dynamic
            reApply: false, // TODO: Update after launch
            accessLevel: ACCESS_LEVEL.INSTITUTION
        }
    }
}

export const updateInstitution = async (institution: InstitutionUser) => {
    if(!institution) return;

    try {
        const result = await axios.post(`${BASE_URL}/institutions/update`, institution);
    } catch(error) {
        throw error;
    }
}


// Sendgrid Helpers
export const inviteClient = async (payload: { name: string, email: string, institution: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const bulkInviteClient = async (payload: Record<any, any>[]) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/bulk-invite-client`, payload);

        return result?.data?.report
    } catch (error) {
        throw error;
    }
}

export const referClient = async (payload: { name: string, email: string, phone: string, address: string, referrer: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/refer-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const referExistingClient = async (payload: { client: Record<any, any>, referrer: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/refer-existing-client`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const inviteExpert = async (payload: { name: string, email: string, institution: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-expert`, payload);
        
        return result
    } catch (error) {
        throw error;
    }
}

export const inviteInstitution = async (payload: { name: string, email: string, invitedBy: string, formLink: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/invite-institution`, payload);
        
        return result
    } catch (error) {
        throw error;
    }
}

export const sendWelcome = async (payload: { name: string, email: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/approval`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const sendAdministratorEmail = async (payload: { name: string, email: string, formLink: string, i: string }, accessLevel: ACCESS_LEVEL) => {
    if (accessLevel !== ACCESS_LEVEL.OWNER) return []; 

    try {
        const link = `${BASE_URL}/mail/admins/${accessLevel}`
       
        const result = await axios.post(link, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const sendCreditApplicationLink = async (payload: { name: string, email: string, formLink: string }) => {
    if (!payload || !payload.name || !payload.email || !payload.formLink) throw Error;

    try {
        const link = `${BASE_URL}/mail/credit/application`
       
        const result = await axios.post(link, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const sendExpertPullEmail = async (payload: { name: string, email: string }) => {
    try {
        const result = await axios.post(`${BASE_URL}/mail/ready`, payload);

        return result
    } catch (error) {
        throw error;
    }
}


// Client Helpers
export const addManagedClient = async (client: ManagedClientUser) => {
    try {
        const result = await axios.post(`${BASE_URL}/clients/add-managed`, client);

        return result
    } catch (error) {
        throw error;
    }
}

export const formatMangedClient: (data: IClientInvitationForm, fiId: string, feId?: string) => ManagedClientUser = (data, fiId, feId) => {
    return {
        userId: '',
        userType: UserTypes.CLIENT_EXPERT_MANAGED,
        userInfo: {
            name: data.name.replace('-', ' '),
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            country: '',
            email: data.email.toLowerCase(),
            institutionId: fiId,
            expertId: feId || '',
            dob: data.birthdate,
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            browserUsed: 'chrome', 
            reApply: false,
            accessLevel: ACCESS_LEVEL.INSTITUTION
        },
    }
}

export const updateClient = async (clientData: ManagedClientUser) => {
    try {
        const link = `${BASE_URL}/clients/update`;
       
        const result = await axios.put(link, clientData);

        return result
    } catch (error) {
        throw error;
    }
}

export const fetchClientById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/single/${id}`);

        return result?.data?.result?.Items[0]
    } catch (error) {
        throw error;
    }
}


// Expert Helpers
export const addExpert = async (expert: ExpertUser) => {
    try {
        const result = await axios.post(`${BASE_URL}/experts/add`, expert);

        return result
    } catch (error) {
        throw error;
    }
}

export const formatExpert: (data: IExpertInvitationForm, fiId: string) => ExpertUser = (data, fiId) => {
    return {
        userId: '',
        userType: UserTypes.FINANCIAL_EXPERT,
        userInfo: {
            name: data.name,
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            country: '',
            email: data.email,
            institutionId: fiId,
            dob: data.birthdate
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            isAdmin: false,
            browserUsed: 'chrome', 
            reApply: false,
            accessLevel: ACCESS_LEVEL.EXPERT,
            invitedBy: fiId
        }
    }
}

export const getInstitutionExperts = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/experts/institution-experts/${id}`);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const getExpertClients = async (id: string) => {
    try {
        const link = `${BASE_URL}/clients/expert-clients/${id}`
       
        const result = await axios.get(link);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const updateExpert = async (expertData: ExpertUser) => {
    try {
        const link = `${BASE_URL}/experts/update`;
       
        const result = await axios.put(link, expertData);

        return result
    } catch (error) {
        throw error;
    }
}

export const fetchExpertById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/experts/single/${id}`);

        return result?.data?.result?.Items[0]
    } catch (error) {
        throw error;
    }
}

// admin helpers
export const getAdministrators = async (accessLevel: ACCESS_LEVEL) => {
    if (accessLevel !== ACCESS_LEVEL.OWNER) return [];
    try {
        const link = `${BASE_URL}/admins/${accessLevel}`
       
        const result = await axios.get(link);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const getAllClients = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/all-clients/${id}`);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const getAllExperts = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/experts/all-experts/${id}`);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const addAdministrator = async (admin: AdminUser) => {
    try {
        const result = await axios.post(`${BASE_URL}/admins/add`, admin);

        return result
    } catch (error) {
        throw error;
    }
}

export const formatAdmin: (data: IAddAdminForm, i: string) => AdminUser = (data, i) => {
    return {
        userId: '',
        userType: UserTypes.CLIENT_VITALS_ADMIN,
        userInfo: {
            name: data.name,
            phone: data.phone,
            email: data.email,
            owner: i
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            browserUsed: 'chrome',
            accessLevel: ACCESS_LEVEL.CVA,
        }
    }
}

export const getClientCreditApplications = async (clientId: string) => {
    try {
        // Integrate Recall for Client's Applications (Most Recent First)
        return [];
    } catch (error) {
        throw error;
    }
}

// Credit Helpers

export const formatPullRequestApplication = (data: Record<any, any>) => {
    return {
        applicationId: uuidv4(),
        applicationType: ApplicationTypes.PULL,
        applicationFormData: {
            name: data.name.replace('-', ' '),
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            email: data.email.toLowerCase(),
            dob: data.dob,
            consent: data.consent,
            ssn: data.ssn
        },
        metadata: {
            expert: data.expertId,
            institution: data.institutionId,
            createdAt: Date.now().toLocaleString(),
            browserUsed: '',
            credit_guid: null,
            app_id: null
        },
        coapplicant1: {
            exists: Boolean(data.name1),
            name: data.name1 || undefined,
            address: data.address1 || undefined,
            city: data.city1 || undefined,
            state: data.state1 || undefined,
            zip: data.zip1 || undefined,
            dob: data.dob1 || undefined,
            ssn: data.ssn1 || undefined,
            consent: data.consent1 || undefined
        },
        coapplicant2: {
            exists: Boolean(data.name2),
            name: data.name2 || undefined,
            address: data.address2 || undefined,
            city: data.city2 || undefined,
            state: data.state2 || undefined,
            zip: data.zip2 || undefined,
            dob: data.dob2 || undefined,
            ssn: data.ssn2 || undefined,
            consent: data.consent2 || undefined
        },
        coapplicant3: {
            exists: Boolean(data.name3),
            name: data.name3 || undefined,
            address: data.address3 || undefined,
            city: data.city3 || undefined,
            state: data.state3 || undefined,
            zip: data.zip3 || undefined,
            dob: data.dob3 || undefined,
            ssn: data.ssn3 || undefined,
            consent: data.consent3 || undefined
        },
        coapplicant4: {
            exists: Boolean(data.name4),
            name: data.name4 || undefined,
            address: data.address4 || undefined,
            city: data.city4 || undefined,
            state: data.state4 || undefined,
            zip: data.zip4 || undefined,
            dob: data.dob4 || undefined,
            ssn: data.ssn4 || undefined,
            consent: data.consent4 || undefined
        },
    }
}

export const addPullApplication = async (app: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/credit/apps/add`, app);

        return result
    } catch (error) {
        throw error;
    }
}

export const fetchAppsByClient = async (email: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/credit/allapps/${email}`);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

export const fetchReport = async (link: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/credit/report`, {link});
    
        return result?.data?.result;
    } catch (error) {
        throw error;
    }
}



// Stripe Helpers
export const addNewCustomer = async (userData: any) => {
    try {
        if(!userData || !userData?.userInfo?.name || !userData?.userInfo?.name) return;
 
        const result = await axios.post(`${BASE_URL}/payment/create-customer`, userData);

        return result
    } catch (error) {
        throw error;
    }
}

export const addNewPaymentMethod = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/add-payment-method`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

export const setupStripeIntent = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/setup-intent`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

export const createStripeIntent = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/create-payment-intent`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

export const addSubscriber = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/add-subscriber`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

export const fetchCustomerAndPayment = async (stripeId: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/retrieve-customer`, {
            customerId: stripeId
        });

        return result;
    } catch(error) {
        throw error;
    }
}

export const fetchCustomerList = async () => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/retrieve-customer-list`);

        return result;
    } catch(error) {
        throw error;
    }
}

export const chargeCustomer = async (customerId: string, paymentMethodId: string, product: PRODUCT_LIST_PROD | PRODUCT_LIST_STAGE) => {
  try {
    const result = await axios.post(`${BASE_URL}/payment/charge-customer`, {
        customerId,
        paymentMethodId,
        productId: product
    });

    const data = result.data;
    if (data.success) {
        return data;
    } else {
        throw data;
    }
  } catch (error) {
      throw error
  }
};

export const oneTimeCharge = async (customerId: string, paymentMethodId: string, amount: number) => {
    try {
      const result = await axios.post(`${BASE_URL}/payment/single-charge`, {
          customerId,
          paymentMethodId,
          amount
      });
  
      const data = result.data;
      if (data.success) {
          return data;
      } else {
          throw data;
      }
    } catch (error) {
        throw error
    }
};

export const fetchStripeCatalogue = async () => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/product-catalogue`);

        return result;
    } catch(error) {
        throw error;
    }
}

export const fetchStripeCustomerSubscriptions = async (customerId: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/customer-subscriptions`, {
            customerId
        });

        return result;
    } catch(error) {
        throw error;
    }
}