import { FunctionComponent } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import { useLocation } from "react-router-dom";
import useInstitutions from "../../hooks/useInstitutions";
import { useUser } from "../../hooks/useUser";
import SquareTile from "../../components/tiles/SquareTile";
import Loading from "../../components/Loading";
import InstitutionInfoTile from "../../components/institutions/InstitutionInfoTile";
import InstitutionApplicationTile from "../../components/institutions/InstitutionApplicationTile";

const InstitutionId: FunctionComponent = () => {
    const { user: { userId } } = useUser();
    const { getInstitutionById, getApplicationById } = useInstitutions(userId);
    const { pathname } = useLocation();

    const pathArray = pathname.split('/');
    const institutionId = pathArray[pathArray.length - 1];
    const institution = getInstitutionById(institutionId);
    const application = getApplicationById(institutionId);
    console.log(institution)
    return (
        <DashboardPageContainer>
            <PageTitle title={`${institution?.userInfo.name}`} />

            <div className="grid grid-cols-2 gap-20">
                <SquareTile>
                 {
                    !institution ? <Loading />
                        : <InstitutionInfoTile institution={institution} />
                 }   
                </SquareTile>

                <SquareTile>
                {
                    !application ? <Loading />
                        : <InstitutionApplicationTile application={application} />
                }
                </SquareTile>
            </div>
        </DashboardPageContainer>
    );
}

export default InstitutionId;