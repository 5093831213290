import { FunctionComponent } from "react";
//TODO: expertTableActions obj & import
import { clientTableActions } from "../../components/actionMenu/types";
import { InviteExpertModal } from "../../components/modals";
import useExperts from "../../hooks/useExperts";
import PageTitle from "../../components/PageTitle";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import useInstitutions from "../../hooks/useInstitutions";
import { useUser } from "../../hooks/useUser";

const Experts: FunctionComponent = () => {
    const { user: { userId } } = useUser();
    const { experts, loading } = useExperts();
    const { institutions } = useInstitutions(userId);

    const headerList = ['checkbox', 'institution', 'name', 'email', 'phone', 'city', 'state', 'actions']
    const formattedExperts = experts?.map((expert) => {
        return {
            userId: expert.userId,
            name: expert.userInfo.name,
            email: expert.userInfo.email,
            phone: expert.userInfo.phone,
            city: expert.userInfo.city,
            state: expert.userInfo.state,
            institution: institutions.find(({id}) => expert.userInfo.institutionId === id)?.name || "ClientVitals"
        }
    });

    return (
        <DashboardPageContainer>
            <PageTitle title="Users" />

            {
                loading ? <Loading />
                        : <Table 
                            columnHeaders={headerList}
                            records={formattedExperts}
                            tableActions={clientTableActions}
                            customButton={<InviteExpertModal />}
                            linkBase="/dashboard/experts"
                        />
            }
        </DashboardPageContainer>
    )
}

export default Experts;