import { FunctionComponent } from "react";

interface IRadialGradientProps {
    height?: string;
    width?: string;
    top?: string;
    left?: string;
    transformCenter?: boolean
}

const RadialGradient: FunctionComponent<IRadialGradientProps> = ({ height, width, top, left, transformCenter }) => (
    <div data-testid='radGradientComp' style={{
                position: (top || left) ? 'absolute' : 'relative', 
                zIndex: -100000,
                top: top ? top : '0',
                left: left ? left : '0',
                transform: transformCenter ? 'translate(-50%, -50%)' : undefined,
                width: width ? width : '200px',
                height: height ? height : '200px',
                borderRadius: '50%',
                background: 'radial-gradient(circle at center, #318BA2, rgba(255,255,255, 0.3), rgba(255, 255, 255, 0))',
            }}
        />  
);

export default RadialGradient;