import { useUser } from "../hooks/useUser";
import { useForm } from "react-hook-form";
import { TextInput } from "../components/inputs";
import { UserTypes } from "../constants";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";
import { FunctionComponent, useState } from "react";
import { changeCreditPassword, formatCreditPassword } from "../utils/SoftPullAPI";
import Button, { BUTTON_THEMES } from "../components/Button";
import DashboardPageContainer from "../components/DashboardPageContainer";
import SquareTile from "../components/tiles/SquareTile";
import PageTitle from "../components/PageTitle";

const Account: FunctionComponent = () => {
     const [showCBCPasswordReset, setShowCBCPasswordReset] = useState<boolean>(false);
     const { updateNotification } = useDashboard();
     const { user } = useUser();
     const {
          register,
          handleSubmit,
          formState: { errors }
     } = useForm({
          defaultValues: {
               prevPassword: '',
               newPassword: '',
          }
     });

     const toggleCBCPasswordReset = () => setShowCBCPasswordReset(!showCBCPasswordReset);

     const submit = async (values: {prevPassword: string, newPassword: string}) => {
          try {
               const uid = (user.userType.toLowerCase() === UserTypes.OWNER.toLowerCase() && process.env.REACT_APP_CV_MASTER_ID) ? process.env.REACT_APP_CV_MASTER_ID :  user.userId.substring(0, 19)
               // @ts-ignore
               const cus_id = user.userType.toLowerCase() === UserTypes.OWNER.toLowerCase() ? process.env.REACT_APP_CV_CUST_ID : user.metadata?.cus_id
       
               const result = await changeCreditPassword({
                    uid,
                    cus_id
               },values.prevPassword, values.newPassword);

               return result;
          } catch (error) {
               updateNotification({
                    theme: NotificationThemes.FAIL,
                    message: 'Failed to Reset Password'
               })
          } finally {
               toggleCBCPasswordReset();
          }
     }
     
     return (
          <DashboardPageContainer>
               <PageTitle title="Account" />
               
               <SquareTile>
                    <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
                         <h4 className="text-2xl">Information</h4>
                    </div>
                    <div>
                         <p><span className="font-semibold">Account Holder:</span> {user.userInfo.name}</p>
                         <p><span className="font-semibold">Email:</span> {user.userInfo.email}</p>
                         <p className="text-primary cursor-pointer hover:underline" onClick={toggleCBCPasswordReset}>
                              Reset CBC Password
                         </p>
                    </div>
                    {
                         showCBCPasswordReset && (
                              <div className="max-w-[350px] mt-4">
                                   <form>
                                        <TextInput
                                             id="prevPassword"
                                             label="PrevPassword"
                                             placeholder="Previous password..."
                                             password
                                             register={register}
                                        />

                                        <TextInput
                                             id="newPassword"
                                             label="NewPassword"
                                             placeholder="New password..."
                                             password
                                             register={register}
                                        />
                                   </form>
                                   <div className="flex justify-between items-center mt-6">
                                        <Button text="Cancel" onClick={toggleCBCPasswordReset} theme={BUTTON_THEMES.INVERSE} />
                                        <Button text="Submit" onClick={handleSubmit(submit)} />
                                   </div>
                              </div>
                         )
                    }
               </SquareTile>
               
          </DashboardPageContainer>
     )
}

export default Account;