import { FunctionComponent } from "react";


interface IDropdownProps {
    id: string;
    currentValue: string;
    onChange: Function;
    defaultText?: string;
    options: Record<any, any>[]
}

const Dropdown: FunctionComponent<IDropdownProps> = ({id, currentValue, onChange, defaultText, options}) => (
    <select 
        id={id} 
        value={currentValue} 
        onChange={e => onChange(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"    
    >
        {
            defaultText && <option selected>{defaultText}</option>
        }
        {
            options.map(({value, label}) => <option value={value}>{label}</option>)
        }
    </select>
);

export default Dropdown;