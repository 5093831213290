import { FunctionComponent, ReactElement, ReactNode, JSXElementConstructor, ElementType } from "react";

interface IPageTitleProps {
    title: string;
    ActionButton?: ElementType;
    ActionButtonProps?: any;
}

const PageTitle: FunctionComponent<IPageTitleProps> = ({ title, ActionButton, ActionButtonProps }) => (
    <div className="w-[100%] flex justify-between border border-secondary border-t-0 border-l-0 border-r-0 mb-3">
        <h1 className="text-3xl uppercase">{title}</h1>
        {ActionButton && <ActionButton {...ActionButtonProps} />}
    </div>
);

export default PageTitle;