import { FunctionComponent } from "react";


interface IUploadClientStepTwoProps {
    csvData: any[];
}

const UploadClientStepTwo: FunctionComponent<IUploadClientStepTwoProps> = ({ csvData }) => (
    <div className="min-h-[350px] w-full border border-primary border-dashed border-2 mb-8">
        <div className="grid grid-cols-2 gap-4 p-2 border border-t-0 border-r-0 border-l-0 border-primary border-dashed border-2">    
            <p className="font-semibold border border-2 border-primary border-dashed border-t-0 border-b-0 border-l-0">NAME</p>
            <p className="font-semibold">EMAIL</p>
        </div>
        {
            csvData?.map((dataPoint: string[]) => {

                return (
                    <div className="grid grid-cols-2 gap-4 p-2 border border-t-0 border-r-0 border-l-0 border-primary border-dashed border-2">
                        <p className="border border-2 border-primary border-dashed border-t-0 border-b-0 border-l-0">{dataPoint[0]}</p>
                        <p>{dataPoint[1]}</p>
                    </div>
                )
            })
        }
    </div>
);

export default UploadClientStepTwo