import { FunctionComponent, useState } from "react";
import Button from "../../Button";
import Modal from "../Modal";
import AddSubscriptionForm from "./AddSubscriptionForm";


const AddSubscriptionModal: FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Subscribe" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Select Available Subscription"
                content={<AddSubscriptionForm onCancel={toggleModal} />}
                onClose={toggleModal} 
            />
        </div>
    )
}

export default AddSubscriptionModal;