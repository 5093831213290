//@ts-ignore
import md5 from 'md5-hash';
//@ts-ignore
import bin2hex from 'locutus/php/strings/bin2hex';
import axios from 'axios';
import { ExpertUser } from '../constants/experts';
import { ACCESS_LEVEL } from '../constants';

const cus_id = process.env.REACT_APP_CV_CUST_ID;
const master_id = process.env.REACT_APP_CV_MASTER_ID;
const master_pwd = process.env.REACT_APP_CV_MASTER_PWD;
const api_url = process.env.REACT_APP_PULL_API;

axios.defaults.proxy = {
    ...axios.defaults.proxy,
    host: api_url || '',
    port:(axios.defaults.proxy && axios.defaults.proxy?.port) ? axios.defaults.proxy.port : 8080
}

let sp_api = `https://staging-proxy-cv-api-d9084cb08cd3.herokuapp.com/api`;

if(process.env.REACT_APP_ENV === 'prod') sp_api = `https://proxy-api-cv-f6c6755ab9ad.herokuapp.com/api`;

console.log({sp_api})

export enum CreditUnions {
    EXPERIAN = 'XPN',
    TRANS_UNION = 'TU',
    EQUIFAX = 'EFX',
    CLARITY_SERVICES = 'CL',
    TRI_MERGE = 'XPN|TU|EFX'
}

export enum CreditActions {
    ADD_USER = 'ADD_USER',
    PWD_CHANGE = 'PWD_CHANGE',
}

export enum ApplicantTypes {
    APPLICANT = 'primary',
    CO_APPLICANT = 'coapplicant'
}

export enum GenerationCodes {
    JUNIOR = 'JR',
    SENIOR = 'SR',
    FIRST = 'I',
    SECOND = 'II',
    THIRD = 'III',
    FOURTH = 'IV',
    FIFTH = 'V'
}

type CreditUser = { 
    name: string;
    uid: string;
    password: string;
    email: string;
    cus_id?: string;
    ip?: string;
}

export const formatCreditPassword = (password: string) => { 
    const hash = md5(password);
    return bin2hex(hash);
}

export const checkIfCreditUserExists = (clientId: string) => { }

export const addCreditUser = async (userData: CreditUser) => {
    if (!master_id || !master_pwd || !api_url) return;

    try {    
        const user_pwd = formatCreditPassword(userData.password);
        const cv_pwd = formatCreditPassword(master_pwd);

        const xmlString =  `
            <?xml version="1.0" encoding="utf-8"?> 
            <data_area> 
                <header_data> 
                    <user_id>${master_id}</user_id> 
                    <user_pwd>${cv_pwd}</user_pwd> 
                    <cus_id>${cus_id}</cus_id> 
                    <action>ADD_USER</action> 
                </header_data> 
                <user_data> 
                    <ip>${userData.ip}</ip> 
                    <name>${userData.name}</name> 
                    <uid>${userData.uid.substring(0,19)}</uid> 
                    <user_pwd>${user_pwd}</user_pwd> 
                    <email>${userData.email}</email> 
                </user_data> 
            </data_area>  
        `
        
        const result = await axios.post(`${sp_api}/add-user`, xmlString, {
            headers:   {
                'Content-Type': 'text/xml; charset=utf-8'
            }
        }); 
        
        return result.data;
    } catch (error) {
        return error;
    }
}

export const changeCreditPassword = async (userData: { uid: string, cus_id: string }, prevPassword: string, newPassword: string) => { 
    if (!userData.cus_id || !api_url) return;
    
    try {    
        const user_pwd = formatCreditPassword(prevPassword);
        const new_pwd = formatCreditPassword(newPassword);

        const xmlString = `
           <?xml version="1.0" encoding="UTF-8" standalone="yes"?>
            <data_area> 
                <header_data> 
                    <user_id>${userData.uid}</user_id> 
                    <user_pwd>${user_pwd}</user_pwd> 
                    <new_pwd>${new_pwd}</new_pwd> 
                    <cus_id>${userData.cus_id}</cus_id> 
                    <action>PWD_CHANGE</action>
                    <xml_format>1</xml_format>
                    <single_joint>0</single_joint>
                    <lookback>0</lookback>
                </header_data> 
            </data_area> 
        `
  
        const result = await axios.post(`${sp_api}/change-password`, xmlString, {
            headers: {
                'Accept': 'text/xml',
                'Content-Type': 'text/xml; charset=utf-8',
            }
        }); 

        return result;
    } catch (error) {
        return error;
    }
}

export const extractNames = (fullName: string) => {
    const nameArray = fullName.trim().split(' ');
    const nameCount = nameArray.length;

    const names = {
        first: '',
        middle: '',
        last: '',
        suffix: ''
    }

    switch(nameCount) {
        case 4: 
            names.first = nameArray[0]
            names.middle = nameArray[1]
            names.last = nameArray[2]
            names.suffix = nameArray[3]
            break;

        case 3: 
            names.first = nameArray[0]
            names.middle = nameArray[1]
            names.last = nameArray[2]
            break;

        default: 
            names.first = nameArray[0]
            names.last = nameArray[1]
            break;
    }

    return names;
}

export const firstPullSingleClientCredit = async (appData: any, expert: ExpertUser, expertPass: string, ssn: string, union: CreditUnions) => {
    try {
        if (!master_id || !master_pwd || !api_url) return;
        const user_pwd = formatCreditPassword(expertPass);
        const names = extractNames(appData.applicationFormData.name);
        
        const coapp1 = appData?.coapplicant1?.exists ? await addCoApplicantData(appData.coapplicant1) : '' 
        const coapp2 = appData?.coapplicant2?.exists ? await addCoApplicantData(appData.coapplicant2) : '' 
        const coapp3 = appData?.coapplicant3?.exists ? await addCoApplicantData(appData.coapplicant3) : '' 
        const coapp4 = appData?.coapplicant4?.exists ? await addCoApplicantData(appData.coapplicant4) : '' 

        const xmlString = `
        <?xml version="1.0" encoding="utf-8"?> 
        <data_area> 
            <header_data>
                <app_id>${appData.metadata.app_id || ''}</app_id>
                <credit_guid>${appData.metadata.credit_guid || ''}</credit_guid>
                <user_id>${expert.metadata.accessLevel === ACCESS_LEVEL.OWNER ? master_id : expert.userId.substring(0, 19)}</user_id> 
                <user_pwd>${user_pwd}</user_pwd> 
                <cus_id>${expert.metadata.cus_id}</cus_id> 
                <single_joint>0</single_joint> 
                <xml_format>1</xml_format>
                <pre_qual>1</pre_qual> 
                <action>${union}</action> 
            </header_data> 
            <applicant_data> 
                <applicant type="primary"> 
                    <person_name> 
                        <first_name>${names.first}</first_name> 
                        <middle_name>${names.middle}</middle_name> 
                        <last_name>${names.last}</last_name> 
                        <generation>${names.suffix}</generation>
                    </person_name> 
                    <address_data> 
                        <address type="current"> 
                            <line_one>${appData.applicationFormData.address}</line_one> 
                            <city>${appData.applicationFormData.city}</city> 
                            <state_or_province>${appData.applicationFormData.state}</state_or_province> 
                            <postal_code>${appData.applicationFormData.zip}</postal_code> 
                        </address> 
                    </address_data> 
                    <birthdate>${appData.applicationFormData.dob}</birthdate>
                    <social>${ssn}</social>
                </applicant>
                ${coapp1}
                ${coapp2}
                ${coapp3}
                ${coapp4}
            </applicant_data> 
        </data_area>
        `
        console.log(xmlString)        
        const result = await axios.post(`${sp_api}/pull-credit`, xmlString, {
            headers:   {
                'Accept': 'text/xml',
                'Content-Type': 'text/xml; charset=utf-8',
            }
        });
        
        return result.data;
    } catch (error) {
        throw error;
    }
}

export const recallClientReport = async (appData: any, expert: ExpertUser, expertPass: string, ssn: string, union: CreditUnions) => {
    if (!api_url) return;
    const user_pwd = formatCreditPassword(expertPass);
    const names = extractNames(appData.applicationFormData.name);

    const coapp1 = appData?.coapplicant1?.exists ? await addCoApplicantData(appData.coapplicant1) : '' 
    const coapp2 = appData?.coapplicant2?.exists ? await addCoApplicantData(appData.coapplicant2) : '' 
    const coapp3 = appData?.coapplicant3?.exists ? await addCoApplicantData(appData.coapplicant3) : '' 
    const coapp4 = appData?.coapplicant4?.exists ? await addCoApplicantData(appData.coapplicant4) : '' 

    try {
        const xmlString = `
        <?xml version="1.0" encoding="utf-8"?> 
        <data_area> 
            <header_data> 
                <app_id>${appData?.metadata?.appId}</app_id> 
                <credit_guid>${appData?.metadata?.creditGuid}</credit_guid> 
                <user_id>${expert.metadata.accessLevel === ACCESS_LEVEL.OWNER ? master_id : expert.userId.substring(0, 19)}</user_id> 
                <user_pwd>${user_pwd}</user_pwd> 
                <cus_id>${expert.metadata.cus_id}</cus_id> 
                <single_joint>0</single_joint> 
                <xml_format>1</xml_format>
                <pre_qual>1</pre_qual> 
                <action>${union}</action> 
            </header_data> 
            <applicant_data> 
                <applicant type="primary"> 
                    <person_name> 
                        <first_name>${names.first}</first_name> 
                        <middle_name>${names.middle}</middle_name> 
                        <last_name>${names.last}</last_name> 
                        <generation>${names.suffix}</generation>
                    </person_name> 
                    <address_data> 
                        <address type="current"> 
                            <line_one>${appData.applicationFormData.address}</line_one> 
                            <city>${appData.applicationFormData.city}</city> 
                            <state_or_province>${appData.applicationFormData.state}</state_or_province> 
                            <postal_code>${appData.applicationFormData.zip}</postal_code> 
                        </address> 
                    </address_data> 
                    <birthdate>${appData.applicationFormData.dob}</birthdate>
                    <social>${ssn}</social>
                </applicant> 
                ${coapp1}
                ${coapp2}
                ${coapp3}
                ${coapp4}
            </applicant_data>  
        </data_area>
        `

        const result = await axios.post(`${sp_api}/pull-credit`, xmlString, {
            headers:   {
                'Accept': 'text/xml',
                'Content-Type': 'text/xml; charset=utf-8',
            }
        });
        
        return result.data
    } catch (error) {
        throw error
    }
}

const addCoApplicantData = async (data: Record<any, any>) => {
    const names = extractNames(data.name);

    return `<applicant type=”coapplicant”>
            <person_name>
                <first_name>${names.first}</first_name> 
                <middle_name>${names.middle}</middle_name> 
                <last_name>${names.last}</last_name> 
                <generation>${names.suffix}</generation>
            </person_name>
            <address_data>
                <address type=”current”>
                    <line_one>${data.address}</line_one>
                    <city>${data.city}</city>
                    <state_or_province>${data.state}</state_or_province>
                    <postal_code>${data.zip}</postal_code>
                </address>
            </address_data>
            <social>${data.ssn}</social>
            <birthdate>${data.dob}</birthdate>
        </applicant>`
}
