import { FunctionComponent, ReactNode, createContext, useEffect, useState } from "react";

export type TableContextType = {
    currentPage: number;
    totalRecords: number;
    totalRecordPages: number;
    recordsShowing: number;
    paginationIndexAdjustment: number;
    activeFilters: string[];
    setCurrentPage: (page: number) => void,
    setTotalRecords: (recordCount: number) => void,
    setRecordsShowing: (showCount: number) => void,
    setPaginationAdjustment: (adjustment: number) => void,
    setActiveFilters: (filtersToAdd: string[]) => void,
    removeFilter: (filterToRemove: string) => void,
    searchTerm: string;
    setSearchTerm: (newTerm: string) => void;
    selectedRecords: Record<string, any>[];
    selectRecord: (newRecord: Record<string, any>) => void;
    removeRecord: (recordToRemove: Record<string, any>) => void;
    isSelected: (recordToCheck: Record<string, any>) => boolean;
    goNext: Function,
    goBack: Function,
    selectPage: (pageNumber: number) => void;
} 

export const TableContext = createContext<TableContextType>({
    currentPage: 1,
    totalRecords: 0,
    totalRecordPages: 0,
    recordsShowing: 10,
    paginationIndexAdjustment: 0,
    activeFilters: [],
    setCurrentPage: () => { },
    setTotalRecords: () => { },
    setRecordsShowing: () => { },
    setPaginationAdjustment: () => { },
    setActiveFilters: () => { },
    removeFilter: () => { },
    searchTerm: '',
    setSearchTerm: () => { },
    selectedRecords: [],
    selectRecord: () => { },
    removeRecord: () => { },
    isSelected: () => false,
    goNext: () => { },
    goBack: () => { },
    selectPage: () => { },
});

interface ITableProviderProps {
    children: ReactNode
}

const TableProvider: FunctionComponent<ITableProviderProps> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [recordsShowing, setRecordsShowing] = useState<number>(10);
    const [totalRecordPages, setTotalRecordPages] = useState<number>(0);
    const [paginationIndexAdjustment, setPaginationIndexAdjustment] = useState<number>(0);
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedRecords, setSelectedRecords] = useState<Record<string, any>[]>([]);

    const goNext: Function = () => {
        setPaginationIndexAdjustment(paginationIndexAdjustment + recordsShowing)
        setCurrentPage(currentPage + 1);
    }

    const goBack: Function = () => {
        setPaginationIndexAdjustment(paginationIndexAdjustment - recordsShowing)
        setCurrentPage(currentPage - 1);
    }

    const selectPage: (pageNumber: number) => void = (pageNumber) => {
        if (pageNumber === 1) {
            setPaginationIndexAdjustment(0)
        } else {
            setPaginationIndexAdjustment((pageNumber-1) * 10)
        }

        setCurrentPage(pageNumber)
    }

    const removeFilter = (filterToRemove: string) => {
        const updatedFilters = activeFilters.filter(filter => filter !== filterToRemove);
        
        setActiveFilters(updatedFilters);
    }

    const selectRecord = (newRecord: Record<string, any>) => {
        const newRecordList = [...selectedRecords, newRecord];
        setSelectedRecords(newRecordList);
    }

    const removeRecord = (recordToRemove: Record<string, any>) => {
        setSelectedRecords(
            selectedRecords.filter(record => JSON.stringify(record) !== JSON.stringify(recordToRemove))
        )
    }

    const isSelected = (recordToCheck: Record<string, any>) => {
        const recordFromSelection = selectedRecords.filter(selectedRecord => JSON.stringify(selectedRecord) === JSON.stringify(recordToCheck))
        return Boolean(recordFromSelection.length);
    }

    const providerValue = {
        currentPage,
        totalRecords,
        recordsShowing,
        paginationIndexAdjustment,
        totalRecordPages,
        activeFilters,
        setCurrentPage,
        setTotalRecords,
        setRecordsShowing,
        setTotalRecordPages,
        setActiveFilters,
        removeFilter,
        setPaginationAdjustment: setPaginationIndexAdjustment,
        searchTerm,
        setSearchTerm,
        selectedRecords,
        selectRecord,
        removeRecord,
        isSelected,
        goNext,
        goBack,
        selectPage
    }

    useEffect(() => {
        setTotalRecordPages(Math.ceil(totalRecords / recordsShowing))
    }, [totalRecords, recordsShowing]);

    return (
        <TableContext.Provider value={providerValue}>
            {children}
        </TableContext.Provider>
    )
}

export default TableProvider;