import { 
    FunctionComponent, 
    ReactNode, 
    createContext, 
    useState,
    Dispatch,
    SetStateAction,
    useEffect
} from "react";
import { getAuthUser, login, logout } from '../utils/Cognito';
import { ACCESS_LEVEL, UserTypes } from "../constants";
import { SignInOutput } from "aws-amplify/auth";
import { getCurrentUserData, getInstitutionUser } from "../utils/ClientVitalsAPI";
import { InstitutionUser } from "../constants/institutions";
import { useNavigate, useLocation } from "react-router-dom";
import { ExpertUser } from "../constants/experts";
import { ManagedClientUser } from "../constants/clients"

export type UserContextType = {
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
    getCurrentUserData: Function;
    setUser: Dispatch<SetStateAction<InstitutionUser | ExpertUser | ManagedClientUser>>
    user: InstitutionUser | ExpertUser | ManagedClientUser,
    loading: boolean;
}

export const UserContext = createContext<UserContextType>({
    login: async () => false,
    logout: async () => { },
    setUser: () => { }, 
    getCurrentUserData: () => { },
    loading: true,
    user: {
        userId: '',
        userType: UserTypes.CLIENT_EXPERT_MANAGED,
        userInfo: {
            name: '',
            address: '',
            country: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            dob: '',
            email: '',
            institutionId: '',
            expertId: ''
        },
        metadata: {
            accessLevel: ACCESS_LEVEL.CLIENT,
            createdAt: '',
            updatedAt: '',
            firstLogin: true,
            cus_id: undefined,
            stripeId: undefined
        },
    }
});

interface IUserProviderProps {
    children: ReactNode
    componentTestingEnvRole?: 'o' | 'i' | 'e' | 'c';
}

const blankeUser = {
        userId: '',
        userType: UserTypes.CLIENT_EXPERT_MANAGED,
        userInfo: {
            name: '',
            city: '',
            address: '',
            state: '',
            country: '',
            zip: '',
            phone: '',
            email: '',
            ein: '',
            institutionId: '',
            expertId: ''
        }, 
        metadata: {
            accessLevel: ACCESS_LEVEL.CLIENT,
            cus_id: undefined,
            stripeId: undefined
        }
    }

const UserProvider: FunctionComponent<IUserProviderProps> = ({ children, componentTestingEnvRole }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const publicRoutes = [
        '/',
        '/login',
        '/register',
        '/client-invitation',
        '/expert-invitation',
        '/admin',
        '/submission',
        '/forgot-password',
        '/applied',
        '/payment-information'
    ];

    const [user, setUser] = useState<InstitutionUser | ExpertUser | ManagedClientUser>(blankeUser);
    const [loading, setLoading] = useState<boolean>(true);

    const userLogin = async (username?: string, password?: string) => {
        if(!loading) setLoading(true);
        try {
            //@ts-ignore
            const currentUser = await getAuthUser();
       
            if (currentUser?.userId) {
                const userData = await getCurrentUserData(currentUser.userId)
            
                setUser(userData);
                setLoading(false);
                return true
            } 
            setLoading(false);
            return false;
        } catch (error) {
            if (!username || !password) return false;

            const signInResult: SignInOutput | null = await login(username, password);

            if (signInResult?.isSignedIn) {
                const authUser = await getAuthUser();
        
                const userData = await getInstitutionUser(authUser?.userId);
                
                setUser(userData);
                setLoading(false);
                return true
            }
            setLoading(false);
            return false;
        }
    }

    const userLogout = async () => {
        try {
            await logout();
            setUser(blankeUser);
            navigate('/');
        } catch (error) {   
            throw error;
        }
    }
    
    const providerValue = {
        user,
        loading,
        setUser,
        login: userLogin,
        logout: userLogout,
        getCurrentUserData
    }
    
    const testProviderValue = {
        login: userLogin,
        setUser,
        user: {
            userId: '',
            userType: UserTypes.CLIENT_EXPERT_MANAGED,
            metadata: { accessLevel: ACCESS_LEVEL.CLIENT },
        }
    }

    useEffect(() => {userLogin()}, []);
    
    useEffect(() => {
        if (!user?.userId?.length && !publicRoutes.includes(pathname) && !pathname.includes('/application/credit')) {
                navigate('/login');
        }

        else if (user?.userId?.length && pathname === '/login') {
            navigate('/dashboard/clients');
        }
    }, [userLogin, logout])

    return (
        <UserContext.Provider value={providerValue}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;