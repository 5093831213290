import { FunctionComponent } from "react"
import { ReactComponent as UploadIcon } from "../../assets/upload-icon-color.svg"


interface IUploadClientStepThreeProps {
    report: Record<any, any>[]
}

const UploadClientStepThree: FunctionComponent<IUploadClientStepThreeProps> = ({report}) => {
    const totalEmails = report.length;
    let sentEmails = 0;

    for(let i = 0; i < report.length; i++) {
        if(report[i]?.status === 'fulfilled') sentEmails += 1;
    }


    return (
        <div className="flex flex-col justify-around items-center mr-12">
            <h3 className="uppercase font-semibold text-center text-2xl my-4">Document Upload Complete</h3>
            <h5 className="uppercase text-center text-2xl my-4">{sentEmails} OUT OF {totalEmails} EMAILS SENT</h5>
            <UploadIcon 
                height={75}
                width={75}
                style={{
                    marginTop: "50px",
                    marginBottom: "75px"
                }}
            />
        </div>
    )
}

export default UploadClientStepThree;