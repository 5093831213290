import { FunctionComponent, useState, useEffect } from "react";
import { getInstitutionApplications } from "../utils/ClientVitalsAPI";
import { institutionApplicationTableActions } from "../components/actionMenu/types";
import DashboardPageContainer from "../components/DashboardPageContainer";
import PageTitle from "../components/PageTitle";
import Table from "../components/table";
import { InviteInstitutionModal } from "../components/modals";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";
import Loading from "../components/Loading";


const AccountApplications: FunctionComponent = () => {
    const [fiApplications, setFiApplications] = useState<any>(null);
    const { updateNotification } = useDashboard();

    const fetchapplications = async () => {
        try {
            const applications = await getInstitutionApplications();

            setFiApplications(applications);
        } catch (error) {
            setFiApplications([]);
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Fetch Institutions'
            });
        }
    }

    const columnHeaders: string[] = ['checkbox', 'account', 'name', 'email', 'phone', 'status', 'city', 'state', 'actions']
    const institutions: Record<string, any>[] = fiApplications?.map((application: any) => {
        return {
            name: application.applicationFormData.name,
            email: application.applicationFormData.email,
            phone: application.applicationFormData.phone,
            status: application.metadata.status,
            city: application.applicationFormData.city,
            address: application.applicationFormData.address,
            zip: application.applicationFormData.zip,
            state: application.applicationFormData.state,
            id: application.applicationId,
            account: application.applicationType
        } 
    });

    useEffect(() => {
        fetchapplications();
    }, [])
    
    return (
        <DashboardPageContainer>
            <PageTitle title='Account Applications' />

            {
                fiApplications === null ? <Loading />
                                        : <Table
                                                columnHeaders={columnHeaders}
                                                records={institutions}
                                                tableActions={institutionApplicationTableActions}
                                                customButton={<InviteInstitutionModal />}
                                            />
            }
        </DashboardPageContainer>
    )
}

export default AccountApplications;