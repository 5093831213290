import * as yup from "yup";

const InviteSchema = yup.object().shape({
    name: yup
        .string()
        .required('Please Enter Company Name'),
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required!"),
});

export default InviteSchema;