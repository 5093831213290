import { ReactComponent as ClientsIcon } from '../assets/all-clients-icon.svg';
import { ReactComponent as ExpertsIcon } from '../assets/experts-icon.svg';
import { ReactComponent as InstitutionsIcon } from '../assets/institution-icon.svg';
import { ReactComponent as AllClientAppsIcon } from '../assets/client-apps-icon.svg'
import { ReactComponent as BillingIcon } from '../assets/billing-icon.svg';
import { ReactComponent as AccountIcon } from '../assets/settings-icon.svg';
import { ReactComponent as UploadIcon } from '../assets/upload-icon.svg';
import { ReactComponent as LogoutIcon } from '../assets/logout-icon.svg';
import { ReactComponent as ReferIcon } from '../assets/refer-user-icon.svg';
import { ReactComponent as ApplicationsIcon } from '../assets/applications-icon.svg'; 

import SideNavigationLink from '../components/sideNavigation/SideNavigationLink';

export const DASHBOARD_LINKS = {
    CLIENTS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/clients' 
        Icon={ClientsIcon} 
        text='Clients' 
        showText={open} 
    />,
    CLIENT_APPLICATIONS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/clients/:id/applications'
        Icon={AllClientAppsIcon} 
        text='Credit Applications' 
        showText={open} 
    />,
    EXPERTS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/experts'
        Icon={ExpertsIcon} 
        text='Users' 
        showText={open} 
    />,
    INSTITUTIONS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/institutions'
        Icon={InstitutionsIcon} 
        text='Institutions' 
        showText={open} 
    />,
    APPLICATIONS: (open: boolean) => <SideNavigationLink 
        url='/dashboard/applications'
        Icon={ApplicationsIcon} 
        text='Account Applications' 
        showText={open} 
    />,
    REFERRAL: (open: boolean) => <SideNavigationLink 
        url='/dashboard/referral-accounts'
        Icon={ReferIcon} 
        text='Referral Accounts' 
        showText={open} 
    />,
    CVA: (open: boolean) => <SideNavigationLink 
        url='/dashboard/admin'
        Icon={UploadIcon} 
        text='ClientVitals Admin' 
        showText={open} 
    />,
    UPLOAD: (open: boolean) => <SideNavigationLink 
        url='/dashboard/upload'
        Icon={UploadIcon} 
        text='Upload' 
        showText={open} 
    />,
    BILLING: (open: boolean) => <SideNavigationLink 
        url='/dashboard/billing'
        Icon={BillingIcon} 
        text='Billing' 
        showText={open} 
    />,
    ACCOUNT: (open: boolean) => <SideNavigationLink 
        url='/dashboard/account'
        Icon={AccountIcon} 
        text='Account'  
        showText={open} 
    />, 
    LOGOUT: (open: boolean, logout: () => void) => <SideNavigationLink 
        url='/'
        Icon={LogoutIcon} 
        text='Logout' 
        showText={open} 
        onClick={logout}
    />
}