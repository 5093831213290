import { FunctionComponent, ComponentType } from "react";
import { Link, useNavigate } from "react-router-dom";

interface ISideNavigationLinkProps {
    url: string,
    Icon: ComponentType<{className?: string}>
    text: string,
    showText: boolean
    onClick?: () => void;
}

const SideNavigationLink: FunctionComponent<ISideNavigationLinkProps> = ({
    url, 
    Icon, 
    text, 
    showText, 
    onClick
}) => (
    <Link onClick={() => {
        if (onClick) {
            onClick()
        }
    }} to={url} className="w-[150px] flex justify-start items-start">
        <Icon className={`${showText ? "h-[16px] w-[16px]" : "h-[20px] w-[20px]"} mr-2`} data-testid={`side-link-${text}`} />
        <span>{showText && text}</span>
    </Link>
);

export default SideNavigationLink;