import { FunctionComponent, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import { addNewPaymentMethod, setupStripeIntent } from "../../../utils/ClientVitalsAPI";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { CARD_ELEMENT_OPTIONS } from "../../../constants/enums";
import Button from "../../Button";

interface IAddPaymentFormProps {
    onCancel: () => void;
} 

const AddPaymentForm: FunctionComponent<IAddPaymentFormProps> = ({ onCancel }) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { updateNotification } = useDashboard();
    const {  user } = useUser();
    const stripe = useStripe();
    const elements = useElements();

    const submit = async (event: any) => {
        event.preventDefault();
     
        if (!stripe || !elements) return;
      
        setIsProcessing(true)
        try {
            const card = elements.getElement(CardElement)

         
            if(!card) {
                throw new Error('Failed to fetch card');
            }

            const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card,
                billing_details: {
                    name: user.userInfo.name,
                },
            });

            if(paymentMethod) {
                //@ts-ignore
                const intent = await setupStripeIntent(user.metadata.stripeId)
    
                const { error, setupIntent } = await stripe.confirmCardSetup(intent.result.client_secret, {
                    payment_method: paymentMethod.id,
                });

                //@ts-ignore
                await addNewPaymentMethod({ customerId: user.metadata.stripeId, paymentMethodId: paymentMethod.id })
            }
            
            
            updateNotification({
                message: 'Payment Method Added Successfully',
                theme: NotificationThemes.SUCCESS
            });

            onCancel()
        } catch (error) {
            console.log(error)
            updateNotification({
                message: 'Payment Method Failed To Add',
                theme: NotificationThemes.FAIL
            });
        } finally {
            setIsProcessing(false);
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="py-10 px-7 flex flex-col">
                <CardElement options={CARD_ELEMENT_OPTIONS} />

                <Button 
                    text={isProcessing ? 'Processing...' : 'Save Card'} 
                    type="submit" 
                    disabled={!stripe || isProcessing} 
                    styles="mx-auto mt-8"
                />
            </div>
        </form>
    )
}

export default AddPaymentForm;