import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import { TextInput } from "../../inputs";

interface ICoApplicantForm {
    applicantNumber: number;
    register: UseFormRegister<any>
}

const CoApplicantForm: FunctionComponent<ICoApplicantForm> = ({applicantNumber, register}) => {

    return (
        <>
        <h4 className="font-semibold text-lg my-8">CoApplicant {applicantNumber}</h4>
        <div className="max-w-screen grid grid-cols-3 gap-2">
            <TextInput 
                id={`name-${applicantNumber}`}
                label={`name ${applicantNumber}`}
                placeholder='Name...'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`address-${applicantNumber}`}
                label={`address ${applicantNumber}`}
                placeholder='Address...'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`city-${applicantNumber}`}
                label={`city ${applicantNumber}`}
                placeholder='City...'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`state-${applicantNumber}`}
                label={`state ${applicantNumber}`}
                placeholder='State...'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`zip-${applicantNumber}`}
                label={`zip ${applicantNumber}`}
                placeholder='Postal Code...'
                register={register}
            />

            <TextInput 
                id={`dob-${applicantNumber}`}
                label={`dob ${applicantNumber}`}
                placeholder='MM/DD/YYYY'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`ssn-${applicantNumber}`}
                label={`ssn ${applicantNumber}`}
                placeholder='Ssn...'
                register={register}
                containerStyles='my-0 p-0'
            />

            <TextInput 
                id={`consent-${applicantNumber}`}
                label={`consent ${applicantNumber}`}
                placeholder='I, name, hereby give my consent to have my credit pulled through ClientVitals Solutions...'
                register={register}
                containerStyles='my-0 p-0'
            />
        </div>
        </>
    )
}

export default CoApplicantForm;


// <applicant type=”coapplicant”>
//   <person_name>
//     <first_name>JOHN</first_name>
//     <middle_name>W</middle_name>
//     <last_name>CHESTER</last_name>
//   </person_name>
//   <address_data>
//     <address type=”current”>
//       <line_one>2990 DAVIS RD</line_one>
//       <city>FAIRBANKS</city>
//       <state_or_province>AK</state_or_province>
//       <postal_code>99709</postal_code>
//     </address>
//   </address_data>
//   <social>666660628</social>
//   <birthdate>04/22/1985</birthdate>
// </applicant>
