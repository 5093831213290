import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Experts, ExpertsId } from '../pages/experts';

const ExpertRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Experts />} />
        <Route path=':id' element={<ExpertsId />} />
    </Routes>
);

export default ExpertRoutes;