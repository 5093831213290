import { FunctionComponent, ReactNode } from "react";

interface IModalProps {
    open: boolean;
    title: string;
    content: ReactNode | string;
    onClose: () => void;
    styles?: string;
    minHeight?: string;
}

const Modal: FunctionComponent<IModalProps> = ({ open, title, content, styles, minHeight, onClose }) => (
    <div
        className={`${open ? 'block' : 'hidden'} bg-black/40 fixed z-10 top-0 left-0 h-full w-[100%] flex items-center justify-center`}
        onClick={onClose}
        data-testid='modal-bg'
    >
        <div className={`w-[500px] min-h-[${minHeight ? minHeight : '330px'}] bg-white rounded ${styles}`} onClick={(event) => event.stopPropagation()}>
            <div className="rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center">
                <h4>
                    {title}
                </h4>
            </div>

            <div className="py-4 px-8">
                {content}
            </div>
        </div>    
    </div>
);

export default Modal;