import { FunctionComponent, MouseEvent, useState } from "react";
import Button from "../../Button";
import { TextInput } from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { useUser } from "../../../hooks/useUser";
import { useDashboard } from "../../../hooks/useDashboard";
import InviteSchema from "../../forms/ValidationScehma/InviteSchema";
import { NotificationThemes } from "../../../context/DashboardContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { inviteInstitution } from "../../../utils/ClientVitalsAPI";

interface IInviteInsitutionFormProps {
    onCancel: () => void;
} 

const InviteInsitutionForm: FunctionComponent<IInviteInsitutionFormProps> = ({ onCancel }) => {
    const { user: { userId } } = useUser();
    const { updateNotification } = useDashboard();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(InviteSchema)
    });

    const submit = async (values: any) => {
        const payload = {
            name: values.name,
            email: values.email,
            invitedBy: userId,
            formLink: `${process.env.REACT_APP_URL_BASE}/register?i=${userId}&name=${values.name.split(' ').join('-')}&email=${values.email}`
        }

        try {
            const result = await inviteInstitution(payload);
         
            if (result.status === 202) {
                updateNotification({
                    message: 'Institution Invite Sent',
                    theme: NotificationThemes.SUCCESS
                });
            }
        } catch (error) {
            updateNotification({
                message: 'Institution Invite Failed To Send',
                theme: NotificationThemes.FAIL
            })
        } finally {
            onCancel();
        }
    }

    return (
        <div>
            <TextInput
                label="Name"
                id="name"
                placeholder="Enter institution name..."
                register={register}
                error={errors?.name?.message}
            />

            <TextInput
                label="Email"
                id="email"
                placeholder="Enter business email..."
                register={register}
                error={errors?.email?.message}
            />

            <div className="w-[100%] flex items-center justify-between mt-6">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </div>
    )
}

export default InviteInsitutionForm;