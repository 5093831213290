import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import { ACCESS_LEVEL } from '../constants';
import { AdminUser } from '../constants/admins';
import { getAdministrators } from '../utils/ClientVitalsAPI';

const useAdmins = (userId: string) => {
    const { user: { metadata: { accessLevel } } } = useUser();

    const [admins, setAdmins] = useState<AdminUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchAdmins = async () => {
        if (!loading) setLoading(true);
        
        try {
            const fetchedAdmins: AdminUser[] = await getAdministrators(accessLevel);

            setAdmins(fetchedAdmins);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userId && accessLevel === ACCESS_LEVEL.OWNER) {
            fetchAdmins()
        }
    }, []);

    return { admins, loading, error, fetchAdmins } 
}

export default useAdmins;