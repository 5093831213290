import { FunctionComponent, useState, useEffect } from "react";
import { getInstitutions } from "../../utils/ClientVitalsAPI";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import { InviteInstitutionModal } from "../../components/modals";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import Loading from "../../components/Loading";


const InstitutionList: FunctionComponent = () => {
    const [institutions, setInstitutions] = useState<any>(null);
    const { updateNotification } = useDashboard();

    const fetchapplications = async () => {
        try {
            const applications = await getInstitutions();

            setInstitutions(applications);
        } catch (error) {
            setInstitutions([]);
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Fetch Institutions'
            });
        }
    }

    const columnHeaders: string[] = ['checkbox', 'name', 'email', 'phone','city', 'state', 'actions']
    const formattedInstitutions: Record<string, any>[] = institutions?.map((user: any) => {
        return {
            name: user.userInfo.name,
            email: user.userInfo.email,
            phone: user.userInfo.phone,
            status: user.metadata.status,
            city: user.userInfo.city,
            state: user.userInfo.state,
            userId: user.userId,
            userType: user.userType
        } 
    });

    useEffect(() => {
        fetchapplications();
    }, [])
    
    return (
        <DashboardPageContainer>
            <PageTitle title='Institutions' />

            {
                institutions === null ? <Loading />
                                        : <Table
                                                columnHeaders={columnHeaders}
                                                records={formattedInstitutions}
                                                tableActions={[]}
                                                customButton={<InviteInstitutionModal />}
                                                linkBase="/dashboard/institutions"
                                            />
            }
        </DashboardPageContainer>
    )
}

export default InstitutionList;