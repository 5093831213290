import { useState, useEffect } from 'react';
import { getInstitutions, getInstitutionApplications } from '../utils/ClientVitalsAPI';


const useInstitutions = (user_id: string) => {
    const [institutions, setInstitutions] = useState<any[]>([]);
    const [applications, setApplications] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchInstitutions = async (id: string) => {
        if (!loading) setLoading(true);
        try {
            const fetchedInstitutions = await getInstitutions();

            setInstitutions(fetchedInstitutions);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const fetchApplications = async (id: string) => {
        if (!loading) setLoading(true);
        try {
            const fetchedApps = await getInstitutionApplications();

            setApplications(fetchedApps);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getInstitutionById = (id: string) => {
        return institutions.find(({ userId }) => userId === id);
    }

    const getApplicationById = (id: string) => {
        return applications.find(({ applicationId }) => applicationId === id);
    }

    useEffect(() => {
        if(user_id) {
            fetchInstitutions(user_id);
            fetchApplications(user_id);
        }
    }, [user_id])

    return { 
        institutions, 
        applications, 
        loading, 
        error, 
        fetchInstitutions, 
        getInstitutionById, 
        getApplicationById
    }
}

export default useInstitutions;