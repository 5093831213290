import { ChangeEventHandler, FunctionComponent } from "react";
import { ReactComponent as DocumentUploadIcon } from '../../assets/doc-upload-icon.svg';


interface IUploadClientStepOneProps {
    handleFileUpload: ChangeEventHandler;
}

const UploadClientStepOne: FunctionComponent<IUploadClientStepOneProps> = ({ handleFileUpload }) => {

    return (
        <div className="w-full flex justify-center items-center py-20">  
            <div>
                <label htmlFor="file" className="block rounded cursor-pointer w-[250px] h-[50px] bg-secondary flex justify-center items-center">
                    <p className="font-medium text-xl leading">Browse Files</p>
                    <DocumentUploadIcon
                        height={25}
                        width={25}
                        className="ml-4"
                    />
                </label>
                <input
                    id="file"
                    type="file"
                    accept=".csv"
                    style={{ visibility: "hidden" }}
                    onChange={handleFileUpload}
                />
            </div>
        </div>
    )
}

export default UploadClientStepOne;