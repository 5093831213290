import { FunctionComponent, StrictMode } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import DashboardRoutes from './routes/DashboardRoutes';
import Landing from './pages/Landing';
import Login from './pages/Login';
import Register from './pages/Register';
import Applied from './pages/Applied';
import UserProvider from './context/UserContext';
import ClientInvitationForm from './components/forms/ClientInvitationForm';
import ExpertInvitationForm from './components/forms/ExpertInvitationForm';
import Submission from './pages/clients/Submission';
import ForgotPassword from './pages/ForgotPassword';
import AdminForm from './components/forms/AdminForm';
import PullRequestForm from './components/forms/PullRequestForm';
import PaymentInformation from './pages/PaymentInformation';

const ClientVitals: FunctionComponent = () => (
  <StrictMode>
    <BrowserRouter>
      <UserProvider>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/applied' element={<Applied />} />
            <Route path='/dashboard/*' element={<DashboardRoutes />} />
            <Route path='/client-invitation' element={<ClientInvitationForm />} />
            <Route path='/expert-invitation' element={<ExpertInvitationForm />} />
            <Route path='/application/credit/:id' element={<PullRequestForm />} />
            <Route path='/admin' element={<AdminForm />} />
            <Route path='/submission' element={<Submission />} />
            <Route path='/payment-information' element={<PaymentInformation />} />
            <Route path='*' element={<h1 className='App-header text-3xl font-bold underline'>Not Found</h1>} />
          </Routes>
      </UserProvider>
    </BrowserRouter>
  </StrictMode>
)

export default ClientVitals;
