import { FunctionComponent } from "react";

interface IBadgeProps {
    text: string;
}

const Badge: FunctionComponent<IBadgeProps> = ({ text }) => (
    <div className='w-[200px] h-[40px] bg-primary text-white uppercase rounded-md text-center flex items-center justify-center text-md'>
        <p>{text}</p>
    </div>
);

export default Badge;