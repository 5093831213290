import { FunctionComponent } from "react";

interface IHeaderCellProps {
    content: string | React.ReactNode;
}

const HeaderCell: FunctionComponent<IHeaderCellProps> = ({ content }) => (
    <th className={`px-4 bg-secondary-light h-[35px] text-sm font-semibold border text-center`}>
        {content}
    </th>
);

export default HeaderCell;