import { FunctionComponent } from "react";
import { InstitutionUser } from "../../constants/institutions";
import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';

interface IInstitutionInfoTileProps {
    toggleForm?: () => void;
    institution: InstitutionUser;
}

const InstitutionInfoTile: FunctionComponent<IInstitutionInfoTileProps> = ({institution, toggleForm}) => (
    <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">Institution Information</h4>
            <p className="mt-2">
                <EditIcon
                    height={25}
                    width={25}
                    onClick={toggleForm}
                    className="cursor-pointer"
                    data-testid='edit-institution-icon'
                />
            </p>
        </div>
        <div className="text-md flex flex-col align-center">
            <p className="mb-3"><span className="font-medium">Name:</span> {institution?.userInfo?.name}</p>
            <p className="mb-3"><span className="font-medium">Email:</span> {institution?.userInfo?.email}</p>
            <p className="mb-3"><span className="font-medium">Phone:</span> {institution?.userInfo?.phone}</p>

            <p className="mb-3"><span className="font-medium">Address:</span> {institution?.userInfo?.address}</p>
            <p className="mb-3"><span className="font-medium">City:</span> {institution?.userInfo?.city}</p>
            <p className="mb-3"><span className="font-medium">State:</span> {institution?.userInfo?.state}</p>
            <p className="mb-3"><span className="font-medium">Postal Code:</span> {institution?.userInfo?.zip}</p>
            {institution?.metadata.invitedBy && <p className="mb-3"><span className="font-medium">Invited By:</span> {institution?.metadata?.invitedBy}</p>}
        </div>
    </>
);
export default InstitutionInfoTile;