import { FunctionComponent } from "react";
import { Checkbox } from "../inputs";
import { TABLE_COLUMN_TYPE } from "../../constants";
import HeaderCell from "./HeaderCell";

interface ITableHeaderProps {
    columnHeaders: string[];
}

const TableHeader: FunctionComponent<ITableHeaderProps> = ({ columnHeaders }) => (
    <thead>
        <tr>
            {
                columnHeaders.map((header: string) => {
                    switch (header) {
                        case TABLE_COLUMN_TYPE.CHECKBOX:
                            return <HeaderCell key={'checkbox'} content={<Checkbox labelText="" />} />
                        
                        case TABLE_COLUMN_TYPE.ACTIONS:
                            return <HeaderCell key={'actions'} content="  " />
                        
                        default:
                            return <HeaderCell
                                key={header}
                                content={
                                    header.replace(/([A-Z])/g, ' $1') // Insert space before capital letters
                                          .replace(/^./, str => str.toUpperCase()) // Uppercase first character
                                }
                            />
                    }
                })
            }
        </tr>
    </thead>
);

export default TableHeader