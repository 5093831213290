import { FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import Button from '../components/Button';
import RadialGradient from '../components/RadialGradient';
import Badge from '../components/Badge';
import Logo from '../assets/logo-color.png'

const Landing: FunctionComponent = () => (
    <div className='max-w-[1150px] mx-auto overflow-hidden'>
        <header className='flex justify-around items-center border-b border-secondary-dark h-screen relative'>
            {/* <RadialGradient top='32%' left='10%' height='410px' width='410px' /> */}
            <RadialGradient top='8%' left='35%' height='210px' width='210px' />
            <RadialGradient top='0' left='65%' height='100px' width='100px' />

            <div>
                <img src={Logo} alt="ClientVitals Logo" height="200px" width="400px" />
            </div>
            <div className='max-w-[400px] flex flex-col'>
                <h3 className='uppercase font-semibold text-2xl mb-6'>
                    Enhancing Experiences
                </h3>
                <p className='mb-12'>
                    The ClientVitals system allows for quicker and more accurate insights to assist in your team's ability to provide reliable, efficient services to your clients. Submit your application today to get your account approved sooner.
                </p>
                <div className='flex justify-between items-center'>
                    <Link to='/register'>
                        <Button text='Apply Now' styles='min-w-[150px]' testId='register-button-landing' />
                    </Link>
                    <Link to='/login'>
                        <Button text='Login' styles='min-w-[150px]' />
                    </Link>
                </div>
            </div>
        </header>
        <section className='flex justify-around items-center border-b border-secondary-dark relative pb-10'>
            <div className='flex flex-col items-center justify-around'>
                <div className='max-w-[800px] border-b border-secondary-dark mb-12'>
                    <h3 className='font-semibold text-2xl my-6'>
                        Welcome to the ClientVitals Solutions Softpull Dashboard
                    </h3>

                    <p className='mb-4'>
                        The ultimate tool for financial professionals seeking to streamline and enhance loan readiness for their clients.
                    </p>
                    
                    <p className='mb-6'>
                    Our dashboard provides quick, secure access to comprehensive client liability profiles through a FICO 8 soft pull, allowing you to assess risks, manage debt, and optimize client lending profiles without impacting credit scores. Designed with financial professionals in mind, the platform simplifies the pre-qualification process and gives you actionable insights to help clients overcome barriers to loan approval.
                    </p>

                    <p className='mb-2'>With ClientVitals Solutions, you'll be empowered to:</p>
                    <ul className='my-10'>
                        <li className='mb-4 ml-6'><b>Quickly identify potential loan denial risks</b> with real-time data.</li>
                        <li className='mb-4 ml-6'><b>Offer pre-qualification tools</b> by utilizing industry-leading soft pull technology.</li>
                        <li className='mb-4 ml-6'><b>Deliver tailored recommendations</b> to clients based on accurate, up-to-date information, ensuring smoother transactions and better outcomes.</li>
                        <li className='mb-4 ml-6'><b>Optimizing client profiles</b> for fast lending approvals.</li>
                    </ul>

                    <p className='my-4'>
                        Whether you're an independent user or part of a larger firm, our easy-to-navigate dashboard supports your workflow, offering flexibility and precision at every step.
                    </p>

                    <p className='mb-10'>Unlock a new level of client engagement with ClientVitals Solutions Softpull Dashboard, and enjoy greater confidence in the loan readiness process.</p>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <Badge text='Team Management' />
                    <Badge text='Client Management' />
                    <Badge text='Soft Pulls' />
                    <Badge text='Data Insights' />
                    <Badge text='Software Support' />
                    <Badge text='Fast Communication' />
                </div>
            </div>
        </section>
        <footer className='min-h-screen relative'>
            <div className='flex justify-around items-center h-screen'>
                <div className='max-w-[350px]'>
                    <h2 className='uppercase font-semibold text-2xl mb-2'>Get In Touch</h2>
                    <h3 className='uppercase font-medium text-xl mb-6 max-w-450px'>Apply to start enhancing productivity</h3>
                    <Link to='/register'>
                        <Button text='Apply Now' styles='min-w-[175px]' />
                    </Link>
                </div>
                <RadialGradient top='70%' left='45%' height='500px' width='500px' transformCenter />
                <RadialGradient top='120px' left='1000px' height='200px' width='200px' transformCenter />
                <div>
                    <h5 className='text-xl font-semibold mb-3 border-b border-secondary-dark'>Contact Us:</h5>
                    <p className='mb-2'>ClientVitals Solutions</p>
                    <p className='mb-2'>Shelburne, VT. 05482</p>
                    <p className='mb-2'>802-922-6268</p>
                    <p className='mb-2'><a href='mailto:clientvitals@clientvitals.com'>clientvitals@clientvitals.com</a></p>
                    {/* <p className='mb-2'>Schedule Demo</p>
                    <p>Feedback Form</p> */}
                </div>
            </div>
            {/* &copy; 2024 - */}
            <p className='flex items-center justify-center my-6'>
                 ClientVitals Solutions | ClientVitals
            </p>
        </footer>
    </div>
);

export default Landing