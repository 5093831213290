import * as yup from "yup";

const ClientInvitationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Please Enter Full Name'),
    password: yup
        .string()
        .required('Please Password'),
    address: yup
        .string()
        .required('Please Enter Address'),
    city: yup
        .string()
        .required('Please Enter City'),
    state: yup
        .string()
        .required('Please Enter State'),
    zip: yup
        .string()
        .required('Please Enter Postal Code'),
    phone: yup
        .string()
        .min(10, 'Please Enter 10 Digit Phone Number')
        .required('Please Enter Postal Code'),
    email: yup
        .string()
        .email("Please enter a valid email!")
        .required("Email is required!"),
    birthdate: yup
        .string()
        .required(),
    // agreedToTerms: yup.boolean().required('Please Read Terms & Conditions'),
    institutionId: yup.string().required('Contact Support For Institution ID'),
    expertId: yup.string(),
});

export default ClientInvitationSchema;