import { FunctionComponent } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer";
import PageTitle from "../components/PageTitle";
import { ReactComponent as Construction } from '../assets/construction.svg';


const UnderConstruction: FunctionComponent = () => (
    <DashboardPageContainer>
        <PageTitle title="Page Under Construction" />
        <div className="w-[100%] h-fit overflow-hidden flex justify-center items-center">
            <Construction height={'500px'} width={'500px'} />
        </div>
    </DashboardPageContainer>
);

export default UnderConstruction;