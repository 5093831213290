import { FunctionComponent, ReactNode } from "react";
import TableBase from "./TableBase";
import TableProvider from "../../context/TableContext";
import { MenuAction } from "../actionMenu/types";

interface ITableProps {
    columnHeaders: string[];
    records: Record<string, any>[];
    tableActions: MenuAction[];
    linkBase?: string;
    customButton?: ReactNode;
}

const Table: FunctionComponent<ITableProps> = ({ columnHeaders, records, customButton, tableActions, linkBase }) => (
    <TableProvider>
        <TableBase 
            columnHeaders={columnHeaders} 
            records={records} 
            tableActions={tableActions} 
            customButton={customButton}
            linkBase={linkBase}
        />
    </TableProvider>
);

export default Table;