import { useState, FunctionComponent, useEffect } from "react";
import Button from "../../Button";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { IInviteExpertForm } from "../../../constants/experts";
import { useUser } from "../../../hooks/useUser";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import { UserTypes } from "../../../constants";
import { fetchStripeCatalogue } from "../../../utils/ClientVitalsAPI";
import Loading from "../../Loading";
import RadioGroup from "../../inputs/RadioGroup";

interface IAddSubscriptionFormProps {
    onCancel: () => void;
} 

const AddSubscriptionForm: FunctionComponent<IAddSubscriptionFormProps> = ({ onCancel }) => {
    const [products, setProducts] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedProduct, setSelectedProduct] = useState<string>('');

    const { handleSubmit } = useForm<IInviteExpertForm>();
    const { user: { userType } } = useUser();
    const { updateNotification } = useDashboard();

    const subscriptionType = userType.toLowerCase() === UserTypes.FINANCIAL_INSTITUTION.toLowerCase() ? 'institution' : 'individual';

    const submit = async (values: IInviteExpertForm) => {
        try {           
            //@ts-ignore
            if (result?.status === 202) {
                updateNotification({
                    message: 'Admin Email Sent',
                    theme: NotificationThemes.SUCCESS
                })
            }
        } catch (error) {
            updateNotification({
                message: 'Admin Email Failed To Send',
                theme: NotificationThemes.FAIL
            });
        } finally {
            onCancel();
        }
    }

    const fetchSubscriptions = async () => {
        setLoading(true)
        try {
            const result = await fetchStripeCatalogue();

            if(Array.isArray(result.data)) {
                const subscriptions = result.data.filter(product => product.name.toLowerCase().includes(subscriptionType))
                
                setProducts(subscriptions);
            }
        } catch(error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(!products.length) fetchSubscriptions();
    }, [])

    const productOptions = products.map(({id, name}) => ({
        id,
        label: name
    }));

    return (
        <form>
            {
                loading ? <Loading /> 
                    : (
                        <div>
                            <RadioGroup
                                name="Plans"
                                options={productOptions}
                                selectedOption={selectedProduct}
                                onChange={(id: string) => setSelectedProduct(id)}
                            />
                        </div>
                    )
            }

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Submit Payment" onClick={handleSubmit(submit)} styles="w-fit px-2" />
            </div>
        </form>
    )
}

export default AddSubscriptionForm;