import { useForm } from "react-hook-form";
import { TextInput } from "../inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDashboard } from "../../hooks/useDashboard";
import { addCreditUser } from "../../utils/SoftPullAPI";
import { IAddAdminForm } from "../../constants/admins";
import { NotificationThemes } from "../../context/DashboardContext";
import { FunctionComponent, MouseEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addAdministrator, formatAdmin } from "../../utils/ClientVitalsAPI";
import { registerAuthUser, markEmailAsVerified } from "../../utils/Cognito";
import DashboardPageContainer from "../DashboardPageContainer";
import AddAdminSchema from "./ValidationScehma/AddAdminSchema";
import PageTitle from "../PageTitle";
import Button from "../Button";
import convert from 'xml-js';
import axios from "axios";

const AdminForm: FunctionComponent = () => {
    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [showVerify, setShowVerify] = useState<boolean>(false);
    const [params] = useSearchParams();
    const { updateNotification } = useDashboard();
    const navigate = useNavigate();

    const i = params.get('i') || '';
    const baseName = params.get('name');
    const email = params.get('email');

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: baseName || '',
            email: email || '',
            i: i || '',
            password: ''
        },
        resolver: yupResolver(AddAdminSchema)
    });

    const submit = async (values: IAddAdminForm) => {
        try {
            const authResult = await registerAuthUser(values.email, values.password);
            console.log({authResult})
            if (authResult?.UserSub) {
                const newAdmin = formatAdmin(values, i);
                newAdmin.userId = authResult?.UserSub;
                const ipResult = await axios.get('https://api.ipify.org?format=json');

                const result = await addAdministrator(newAdmin);
                
                const cbcResult = await addCreditUser({
                    uid: result.data.result.adminId,
                    name: newAdmin.userInfo.name,
                    email: newAdmin.userInfo.email,
                    password: values.password,
                    ip: ipResult.data.ip
                });
                console.log(cbcResult)
                const result2 = convert.xml2js(cbcResult, { compact: true });
             
                newAdmin.userId = result.data.result.adminId;

                //@ts-ignore
                newAdmin.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
                
                await addAdministrator(newAdmin);
                navigate('/submission');
            }
        } catch (error: unknown) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Submit Form'
            });
        }
    }

    const verify = async (data: IAddAdminForm) => {
        try {
            await markEmailAsVerified(data.email);
            
        } catch (error) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Verify'
            })
        }
    }

    const submissionSwitch = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        
        if (showVerify) return handleSubmit(verify)();
        else return handleSubmit(submit)();
    }

    return (
        <DashboardPageContainer>
            <PageTitle title="Complete Form to Complete Onboarding" />

            <form className="max-w-screen grid grid-cols-2 gap-1">
            {
                showVerify ? (
                                <TextInput
                                    label='Code'
                                    id='code'
                                    placeholder='Enter verification code...'
                                    register={register}
                                    containerStyles='w-full'
                                    key='code'
                                    error={errors?.code?.message}
                                />
                )
                    : (
                    <>
                    <TextInput 
                        id='name'
                        label='name'
                        placeholder='Name...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.name?.message}
                    />
                    <TextInput 
                        id='email'
                        label='email'
                        placeholder='Email...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.email?.message}            
                    />
                    <TextInput 
                        id='phone'
                        label='phone'
                        placeholder='Phone...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.phone?.message}   
                    />   
                    <TextInput 
                        id='password'
                        label='password'
                        placeholder='Password...'
                        register={register}
                        containerStyles='my-0 p-0'
                        password
                        error={errors.password?.message}   
                    />            
                    </>
                )
            }
                <Button
                    text={showVerify ? 'Confirm': 'Submit'}
                    onClick={submissionSwitch}
                    styles='w-[300px] mt-12'
                />
            </form>
        </DashboardPageContainer>
    )
}

export default AdminForm;