import { FunctionComponent } from "react";
import Button from "../../Button";
import { TextInput } from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { IInviteExpertForm } from "../../../constants/experts";
import { useUser } from "../../../hooks/useUser";
import { addNewCustomer, inviteExpert } from "../../../utils/ClientVitalsAPI";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import { ACCESS_LEVEL } from "../../../constants";

interface IInviteExpertFormProps {
    onCancel: () => void;
} 

const InviteExpertForm: FunctionComponent<IInviteExpertFormProps> = ({ onCancel }) => {
    const { register, handleSubmit } = useForm<IInviteExpertForm>();
    const { user } = useUser();
    const { userId } = user;

    const { updateNotification } = useDashboard();

    const submit = async (values: IInviteExpertForm) => {
        const payload = {
            name: values.name,
            email: values.email,
            institution: user?.metadata?.accessLevel === ACCESS_LEVEL.INSTITUTION ? userId : process.env.REACT_APP_SUB_ACCOUNT || "",
            formLink: `${process.env.REACT_APP_URL_BASE}/expert-invitation?fi=${userId}&name=${values.name.split(' ').join('-')}&email=${values.email}`
        }
        
        try {
            const result = await inviteExpert(payload);

            if (result.status === 202) {
                updateNotification({
                    message: 'User Invite Sent',
                    theme: NotificationThemes.SUCCESS
                })
            }
        } catch (error) {
            updateNotification({
                message: 'User Invite Failed To Send',
                theme: NotificationThemes.FAIL
            });
        } finally {
            onCancel();
        }
    }

    return (
        <form>
            <TextInput
                label="Name"
                id="name"
                placeholder="Enter user's name..."
                register={register}
            />

            <TextInput
                label="Email"
                id="email"
                placeholder="Enter user's email..."
                 register={register}
            />

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </form>
    )
}

export default InviteExpertForm;