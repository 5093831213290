import { FunctionComponent, ReactNode } from "react";

interface ISquareTileProps {
    children: ReactNode;
    styles?: string;
}

const SquareTile: FunctionComponent<ISquareTileProps> = ({ children, styles }) => (
    <div data-testid='square-tile' className={`min-w-[450px] w-[100%] min-h-[400px] shadow-lg p-4 ${styles}`}>
        {children}
    </div>
);

export default SquareTile;