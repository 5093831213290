import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import {Clients, ClientId, ClientApplications, ClientApplicationId, Credit} from '../pages/clients';

const ClientRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Clients />} />
        <Route path=':id' element={<ClientId />} />
        <Route path=':id/applications' element={<ClientApplications />} />
        <Route path=':id/applications/:appId' element={<ClientApplicationId />} />
        <Route path='credit' element={<Credit />} />
    </Routes>
);

export default ClientRoutes;