import { FunctionComponent } from "react";


interface IFillerCircleeProps {
    completed: boolean;
}

const FillerCircle: FunctionComponent<IFillerCircleeProps> = ({ completed }) => (
    <div className={`w-[18px] h-[18px] mx-4 rounded-full flex justify-center items-center text-white ${completed ?  'bg-primary' : 'bg-secondary-dark'}`} />
);

export default FillerCircle;