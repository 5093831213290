import { FunctionComponent } from "react";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";

const DashboardPageContainer: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const { notification } = useDashboard();

    return (
        <section className="h-full min-h-dvh w-[100%] px-6 pl-10 py-2 realtive" data-testid="dashboard-page-container">
            {
                notification.message && (
                    <p className={`fixed top-5 right-7 text-lg ${notification.theme === NotificationThemes.FAIL ? 'text-danger' : 'text-success'}`}>
                        {notification.message}
                    </p>
                )
            }
            {children}
        </section>
    );
}

export default DashboardPageContainer;