import { FunctionComponent, useState } from "react";
import InviteInsitutionForm from "./InviteInstitutionForm";
import Button from "../../Button";
import Modal from "../Modal";

const InviteInstitutionModal: FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Invite" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Invite Institution"
                content={<InviteInsitutionForm onCancel={toggleModal} />}
                onClose={toggleModal} 
            />
        </div>
    )
}

export default InviteInstitutionModal;