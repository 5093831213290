import { FunctionComponent } from "react";
import Button from "../../components/Button";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import { useNavigate, useSearchParams } from "react-router-dom";


const Submission: FunctionComponent = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate()
    const isExpert = params.get('expert');

    return (
        <DashboardPageContainer>
            <PageTitle title="Submission Recieved" />
            <div className="w-[100%] max-w-screen h-full flex flex-col justify-center items-center mt-14">
                <p className="mb-2 text-xl font-semibold">Congratulations on your form submission!</p>
                {isExpert && <p className="mb-5">Your Account Is Now Active</p>}
                <Button 
                    text={isExpert ? 'Login' : 'Home'} 
                    onClick={isExpert ? () => navigate('/login') : () => navigate('/')}
                />
            </div>
        </DashboardPageContainer>
    );
}

export default Submission;