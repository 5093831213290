import { FunctionComponent, useContext } from "react";
import { ReactComponent as LeftArrow } from '../assets/arrow-left-highlighted.svg';
import { ReactComponent as RightArrow } from '../assets/arrow-right-highlighted.svg';
import { ReactComponent as LeftArrowDisabled } from '../assets/arrow-left-disabled.svg';
import { ReactComponent as RightArrowDisabled } from '../assets/arrow-right-disabled.svg';
import { TableContext, TableContextType } from "../context/TableContext";

const Pagination: FunctionComponent = () => {
    const {
        recordsShowing,
        totalRecords,
        currentPage,
        totalRecordPages,
        goNext,
        goBack,
        selectPage
    } = useContext(TableContext) as TableContextType;

    return (
        <div className="flex justify-end items-end mt-2">
            <p className="text-xs mr-2" data-testid="pagination-total-records">
                {totalRecords} Total Items
            </p>

            <div className="w-fit flex items-center justify-around">
                {
                    currentPage === 1 ? <LeftArrowDisabled className="h-[20px] w-[20px] mr-2" />
                                      : <LeftArrow className="h-[20px] w-[20px] mr-2 cursor-pointer" onClick={() => goBack()} data-testid='back-pagination' />
                }
                {
                    Array.from({ length: totalRecordPages }).map((_: any, index: number) => (
                        <button key={index + 1}
                                onClick={() => selectPage(index + 1)}
                                data-testid={`page-${index+1}`}
                                className={`rounded-sm h-[20px] w-[20px] mr-2 border ${currentPage === index + 1 ? 'border-primary text-primary' : 'border-secondary-dark text-secondary-dark'} p-2 flex items-center justify-center`}
                        >
                            {index + 1}
                        </button>
                    ))
                }
                {
                    currentPage === (Math.ceil(totalRecords / recordsShowing))
                        ? <RightArrowDisabled className="h-[20px] w-[20px]" />
                        : <RightArrow className="h-[20px] w-[20px] cursor-pointer" onClick={() => goNext()} data-testid='next-pagination' />
                }
            </div>
        </div>
    );
}

export default Pagination;