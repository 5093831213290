import { FunctionComponent, MouseEvent } from "react";

export enum BUTTON_THEMES {
    PRIMARY = 'PRIMARY',
    INVERSE = 'INVERSE',
    DISABLED = 'DISABLED'
}

interface IButtonProps {
    text: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    theme?: 'PRIMARY' | 'INVERSE' | 'DISABLED';
    type?: 'submit';
    styles?: string;
    testId?: string;
    cyId?: string;
    disabled?: boolean;
}

const Button: FunctionComponent<IButtonProps> = ({ text, onClick, styles, theme, type, testId, disabled, cyId }) => {
    let themeStyles;

    switch (theme) {
        case BUTTON_THEMES.PRIMARY:
            themeStyles = 'bg-primary text-white';
            break;
        
        case BUTTON_THEMES.INVERSE:
            themeStyles = 'bg-white text-primary border border-primary';
            break;
        
        default:
            themeStyles = 'bg-primary text-white';
            break;
    }

    return (
        <button
            className={`w-[120px] h-[35px] uppercase rounded-md ${styles} ${themeStyles}`}
            onClick={onClick}
            data-testid={testId}
            type={type}
            disabled={disabled}
            data-cy={cyId}
        >
            {text}
        </button>
    );
}
export default Button;