import { SVGProps, FunctionComponent } from 'react';

interface INavigationStateIconProps {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    openState: boolean;
    toggleOpenState: (openState: boolean) => void;
}

const NavigationStateIcon: FunctionComponent<INavigationStateIconProps> = ({ Icon, openState, toggleOpenState }) => (
    <button
        className={`flex justify-end mt-4 cursor-pointer ${openState ? "mr-4" : "mr-1"}`}
        onClick={() => toggleOpenState(!openState)}
        data-testid='navigation-state-icon'
        data-cy='navigation-state-icon'
    >
        <Icon className={`${openState ? "h-[16px] w-[16px]" : "h-[20px] w-[20px]"}`} />
    </button>
);

export default NavigationStateIcon;