import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import ExpertInfoTile from "../../components/experts/ExpertInfoTile";
import EditExpertForm from "../../components/forms/EditExpertForm";
import PageTitle from "../../components/PageTitle";
import useExperts from "../../hooks/useExperts";
import WideTile from "../../components/tiles/WideTile";
import { getInstitutionApplicationById } from "../../utils/ClientVitalsAPI";
import Loading from "../../components/Loading";
import useInstitutions from "../../hooks/useInstitutions";

const ExpertsId: FunctionComponent = () => {
    const [showForm, setShowForm] = useState<boolean>(false);
    
    const { getExpertById } = useExperts();
    const { pathname } = useLocation();
    const pathArray = pathname.split('/');
    const expertId = pathArray[pathArray.length - 1];
    const expert = getExpertById(expertId);

    const { institutions } = useInstitutions(expertId);
    const institution = institutions?.find(({userId}) => userId === expert?.userInfo?.institutionId);
    const formToggle = () => setShowForm(!showForm)

    return (
        <DashboardPageContainer>
            <PageTitle title={`${expert?.userInfo.name}`} />
            <WideTile>
            {
                ((!showForm && !expert)) ? <Loading />
                    : (!showForm && expert) ? <ExpertInfoTile expert={expert} institution={institution?.name} formToggle={formToggle} />
                        : <EditExpertForm expert={expert} institution={institution?.name} toggleForm={formToggle} />
            }
            </WideTile>
        </DashboardPageContainer>
    )
}

export default ExpertsId;