import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientRoutes from './ClientRoutes';
import ExpertRoutes from './ExpertRoutes';
import InstitutionRoutes from './InstitutionRoutes';
import SideNavigation from '../components/sideNavigation';
import DashboardProvider from '../context/DashboardContext';
import Upload from '../pages/Upload';
import ClientVitalsAdmin from '../pages/admin/ClientVitalAdmins';
import Account from '../pages/Account';
import Billing from '../pages/Billing';
import AccountApplications from '../pages/AccountApplications';
import ReferralAccounts from '../pages/ReferralAccounts';

const DashboardRoutes: FunctionComponent = () => (
    <DashboardProvider>
            <section className='flex flex-row w-vw h-content'>
                <SideNavigation />
                <Routes>
                    <Route path='clients/*' element={<ClientRoutes />} />
                    <Route path='experts/*' element={<ExpertRoutes />} />
                    <Route path='institutions/*' element={<InstitutionRoutes />} />
                    <Route path='applications' element={<AccountApplications />} />
                    <Route path='referral-accounts/*' element={<ReferralAccounts />} />
                    <Route path='admin' element={<ClientVitalsAdmin />} />
                    <Route path='upload' element={<Upload />} />
                    <Route path='billing' element={<Billing />} />
                    <Route path='account' element={<Account />} />
                </Routes>
            </section>
    </DashboardProvider>
);

export default DashboardRoutes;