import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';
import { FunctionComponent } from "react";
import { ExpertUser } from '../../constants/experts';

interface IExpertInfoTileProps {
    formToggle: () => void;
    expert: ExpertUser
    institution?: string
}

const ExpertInfoTile: FunctionComponent<IExpertInfoTileProps> = ({ expert, institution, formToggle }) => (
    <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">User Information</h4>
            <p className='mt-1'>
                <EditIcon
                    height={25}
                    width={25}
                    onClick={formToggle}
                    className="cursor-pointer"
                    data-testid='edit-expert-icon'
                />
            </p>
        </div>

        <div className="text-md flex align-center">
            <div className="mr-[5%]">
                <p className="mb-3"><span className="font-medium">Name:</span> {expert?.userInfo?.name}</p>
                <p className="mb-3"><span className="font-medium">Email:</span> {expert?.userInfo?.email}</p>
                <p className="mb-3"><span className="font-medium">Phone:</span> {expert?.userInfo?.phone}</p>
                <p className="mb-3"><span className="font-medium">DoB:</span> {expert?.userInfo?.dob}</p>
            </div>

            <div className="mr-[5%]">
                <p className="mb-3"><span className="font-medium">Address:</span> {expert?.userInfo?.address}</p>
                <p className="mb-3"><span className="font-medium">City:</span> {expert?.userInfo?.city}</p>
                <p className="mb-3"><span className="font-medium">State:</span> {expert?.userInfo?.state}</p>
                <p className="mb-3"><span className="font-medium">Postal Code:</span> {expert?.userInfo?.zip}</p>
            </div>

            <div>
                <p className="mb-3"><span className="font-medium">Account Creation:</span> {expert?.metadata?.createdAt}</p>
                <p className="mb-3"><span className="font-medium">Last Update:</span> {expert?.metadata?.updatedAt}</p>
                <p className="mb-3"><span className="font-medium">Admin:</span> {String(expert?.metadata?.isAdmin)}</p>
                {institution && <p className="mb-3"><span className="font-medium">Invited By:</span> {institution}</p>}
            </div>
        </div>
    </>
);

export default ExpertInfoTile;