import { FunctionComponent, useState } from "react";
import Button from "../../Button";
import Modal from "../Modal";
import InviteClientForm from "./InviteClientForm";

const InviteClientModal: FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Invite" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Invite Clients"
                content={<InviteClientForm onCancel={toggleModal} />}
                onClose={toggleModal} 
            />
        </div>
    )
}

export default InviteClientModal;