import { fetchReport } from "./ClientVitalsAPI";

const parseStringToObject = (input:string) => {
    const regex = /([^:]+): ([^:]+)(?=\s|$)/g; // Regular expression to match key-value pairs
    const obj: Record<any, any> = {};
    let match;
  
    while ((match = regex.exec(input)) !== null) {
      const key = match[1].trim().replace(/\s+/g, ''); // Remove spaces for the key
      const value = match[2].trim();
      obj[key] = value;
    }
  
    return obj;
}

export const parseCreditReport = async (creditReport: Record<any, any>, setScoreCardReports: Function) => {
    if(!creditReport) return;

    const cbcReport = creditReport["XML_INTERFACE"]["CREDITREPORT"]["REPORT"]["_cdata"];
    const parser = new DOMParser();
    const doc = parser.parseFromString(cbcReport, 'text/html')
    
    const scorecards = doc.querySelector('.score_cards')?.children
    
    if(scorecards) {
        try {
            const scoreLength = scorecards.length;
            const scoreObjs = [];

        for(let i = 0; i < scoreLength; i++) {
            const card = scorecards[i];
            const score = card.querySelector('.score')?.textContent?.trim() || 'N/A';
            const type = card.querySelector('.score_name')?.textContent?.trim() || 'N/A';
            //@ts-ignore
            const image = card.querySelector('.bureau_image')?.childNodes[1]?.src
            const guid = card.querySelector('.score_menu .view')?.className.split(' ')[2];
      
            const reportLink = process.env.REACT_APP_ENV === 's' ? `https://dev.cbcecredit.com/capp/recall.php?GUID=${guid}` : `https://www.creditbureauconnection.com/capp/recall.php?GUID=${guid}&uid=vcc4500109`
            
            const report = await fetchReport(reportLink);

            scoreObjs.push({
                score,
                type,
                image,
                reportLink,
                report
            });
        }
          
            setScoreCardReports(scoreObjs)
        } catch (error) {
            console.log(error)
        }
    }
}

export const analyzeReport = async (iframeRefReport: any, setReportSummary: Function) => {
    //@ts-ignore
    const iframeDocument = iframeRefReport.current?.contentDocument || iframeRefReport.current?.contentWindow.document;
    
    const elements = iframeDocument.querySelectorAll('table');

    let amountPastDue;
    let scores: Record<any, any>[] = [];
    let ninetyDays;
    let sixtyDays;
    let thirtyDays;

    let availableCredit;
    let collectionItems;
    let warnings;
    let judgements;

    let scoreCardCount = 1;
    elements.forEach((element: any) => {
        if(element.innerText) {
            if(element.innerText.toLowerCase().includes('scorecard:')) {
                if(scoreCardCount === 1) scoreCardCount = 2;
                else if (scoreCardCount === 2) {
                    scoreCardCount = 3;
                    const cleanedString = element.innerText.toLowerCase().substring(11).replaceAll(/(\t\n|\n|\t)/gm, " ").replace(/\s+/g, ' ');
                    
                    const stringArray = cleanedString.split('scorecard:').filter((_: any, index: number) => index !== 0);
                 
                    stringArray.forEach((card: string) => {
                        const cardString = 'scorecard:' + card;

                        const cardObj = parseStringToObject(cardString);

                        scores.push(cardObj)
                    })
                }
            }

            if(element.innerText.toLowerCase().includes('amount past due')) {
                const cleanedString = element.innerText.toLowerCase().replaceAll(/(\t\n|\n|\t)/gm, " ").replace(/\s+/g, ' ');
                const stringObj = parseStringToObject(cleanedString)

                amountPastDue = stringObj.due;
            }

            if(element.innerText.toLowerCase().includes('90+ days')) {
                const cleanedString = element
                    .innerText
                    .toLowerCase()
                    .replaceAll('30 days', '30days')
                    .replaceAll('60 days', '60days')
                    .replaceAll('90+ days', '90days')
                    .replaceAll(/(\t\n|\n|\t)/gm, " ")
                    .replace(/\s+/g, ' ');
                console.log({cleanedString})
                const stringObj = parseStringToObject(cleanedString);
                console.log({stringObj})
                ninetyDays = stringObj['90days'];
                sixtyDays = stringObj['60days'];
                thirtyDays = stringObj['30days'];
                collectionItems = stringObj.collections.split(' ')[0];
            }

            if(element.innerText.toLowerCase().includes('available %')) {
                // console.log(element.innerText)
                const cleanedString = element
                    .innerText
                    .toLowerCase()
                    .replaceAll('available %', 'availableCredit')
                    .replaceAll(/(\t\n|\n|\t)/gm, " ")
                    .replace(/\s+/g, ' ');
                    
                const stringObj = parseStringToObject(cleanedString);

                availableCredit = stringObj.availableCredit
            }

            if(element.innerText.toLowerCase().includes('total # of jdgs')) {
                const cleanedString = element
                    .innerText
                    .toLowerCase()
                    .replaceAll('total # of jdgs', 'jdgs')
                    .replaceAll('mnths since last jdg', 'jdgMonths')
                    .replaceAll('jdgs amt', 'jdgAmount')
                    .replaceAll('small claims jdgs', 'smallClaims')
                    .replaceAll('court jdgs', 'court')
                    .replaceAll('foreclosure jdgs', 'foreclosure')
                    .replaceAll(/(\t\n|\n|\t)/gm, " ")
                    .replace(/\s+/g, ' ');
                
                const stringObj = parseStringToObject(cleanedString);
              
                judgements = stringObj.jdgs
            }

            if(element.innerText.toLowerCase().includes('warning messages')) {
                // console.log(element.innerText)
                const cleanedString = element
                    .innerText
                    .toLowerCase()
                    .replace('warning messages hide ', '')
                    .replaceAll(/(\t\n|\n|\t)/gm, " ")
                    .replace(/\s+/g, ' ');
                    
                const warningMessages = cleanedString.split('-');
           
                warningMessages[0] = warningMessages[0].replace('warning messages hide ', '');
                warnings = warningMessages;
            }
        }
    });
    console.log("================== REPORT ==================")
    console.log({amountPastDue});
    console.log({ninetyDays})
    console.log({sixtyDays})
    console.log({thirtyDays})
    console.log({scores})
    console.log({availableCredit})
    console.log({collectionItems});
    console.log({judgements});
    console.log({warnings});

    setReportSummary({
        amountPastDue,
        ninetyDays,
        sixtyDays,
        thirtyDays,
        scores,
        availableCredit,
        collectionItems,
        judgements,
        warnings
    })
}