import { forgotPassword, resetPassword } from "../../utils/Cognito";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../inputs";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import Button from "../Button";

interface IResetPasswordsFormProps {
    setError: Function
}

export interface IForgotPasswordForm {
    email: string;
    password: string;
    code: string;
}

const ResetPasswordsForm: FunctionComponent<IResetPasswordsFormProps> = ({setError}) => {
    const [showReset, setShowReset] = useState<boolean>(false);
    const { register, handleSubmit } = useForm<IForgotPasswordForm>({
        defaultValues: {
            email: '',
            password: '',
            code: '',
        }
    });

    const navigate = useNavigate();

    const submitForgotPassword: (values: IForgotPasswordForm) => void = async ({ email }) => {
        try {
            const result = await forgotPassword(email);

            if (result.$metadata.httpStatusCode === 200) {
                setShowReset(true);
            }
        } catch (error) {
            setError('Failed To Find User')
        }
    }

    const submitResetPassword: (values: IForgotPasswordForm) => void = async ({ email, password, code}) => {
        try {
            const result = await resetPassword(email, password, code);

            if (result.$metadata.httpStatusCode === 200) {
                navigate('/login');
            }
        } catch (error) {
            setError('Failed To Reset Password');
        }
    }

    return (
        <form className='py-4 px-8 flex flex-col'>
            {
                showReset ? (
                    <>
                    <TextInput
                        label='Password'
                        id='password'
                        placeholder='New password...'
                        password
                        containerStyles='mb-4'
                        register={register}
                    />

                    <TextInput
                        label='Code'
                        id='code'
                        placeholder='Confirmation Code...'
                        password
                        containerStyles='mb-4'
                        register={register}
                        />
                    <Button
                        text='Reset Password'
                        onClick={handleSubmit(submitResetPassword)}
                        styles='self-center w-[50%] mb-8'
                    />
                    </>
                ) : (
                    <>
                    <TextInput
                        label='Email'
                        id='email'
                        placeholder='Enter email...'
                        register={register}
                        containerStyles='mb-4'
                    />
                    <Button
                        text='Submit'
                        onClick={handleSubmit(submitForgotPassword)}
                        styles='self-center w-[50%] mb-8'
                    />
                    </>
                )
            }
            
            <Link to='/login' className='text-primary self-center'>
                Login
            </Link>
        </form>
    )
}

export default ResetPasswordsForm;