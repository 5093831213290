import { FunctionComponent, ReactNode } from "react";

interface ISlimTileProps {
    children: ReactNode;
    styles?: string;
}

const SlimTile: FunctionComponent<ISlimTileProps> = ({ children, styles }) => (
    <div data-testid='slim-tile' className={`max-w-[550px] w-[100%] min-h-[200px] shadow-xl p-4 ${styles}`}>
        {children}
    </div>
);

export default SlimTile;