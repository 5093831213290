import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadialGradient from '../components/RadialGradient';
import Button from '../components/Button';

const Applied: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className='w-[500px] bg-white rounded-lg border-primary border-2 absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        APPLICATION SUBMITTED
                    </h4>
                </div>

                <div className='py-4 px-8 flex flex-col items-center justify-around text-center'>
                    <h3 className='text-xl font-medium mb-2'>Thank you for applying</h3>
                    <p>Applications are typically reviewed and decided in 3-5 business days.</p>

                    <Button
                        text='HOME'
                        styles='my-8'
                        onClick={() => navigate('/')}
                    />
                </div>
            </div>  
        </div>
    )
}

export default Applied;