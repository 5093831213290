import { FunctionComponent, useState } from "react";
import Button from "../../Button";
import Modal from "../Modal";
import ReferClientForm from "./ReferClientForm";
import ReferExistingClientForm from "./ReferExistingClientForm";

interface IReferClientModalProps {
    existingClient?: Record<any, any>;
}

const ReferClientModal: FunctionComponent<IReferClientModalProps> = ({ existingClient }) => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Refer" styles="ml-4" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Refer Client to Consulting"
                content={existingClient ? <ReferExistingClientForm onCancel={toggleModal} client={existingClient} /> : <ReferClientForm onCancel={toggleModal} />}
                onClose={toggleModal} 
            />
        </div>
    )
}

export default ReferClientModal;