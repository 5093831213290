import React from "react";
import { useState } from "react";
import { FunctionComponent } from "react";
import { ManagedClientUser } from "../../constants/clients";
import Button, { BUTTON_THEMES } from "../Button";
import { TextInput } from "../inputs";
import Modal from "../modals/Modal";
// import EditClientSchema from "./ValidationScehma/EditClientSchema";
import EditClientForm from "../forms/EditClientForm";
import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';
import { useUser } from "../../hooks/useUser";
import { updateClient } from "../../utils/ClientVitalsAPI";
import useClients from "../../hooks/useClients";

interface IClientInfoTileProps {
    toggleForm: () => void;
    client: ManagedClientUser
};

const ClientNotesFeature: FunctionComponent<IClientInfoTileProps> = ({ client, toggleForm }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newNote, setNewNote] = useState('')
    const [notes, setNotes] = useState([]);
    const { user } = useUser();
    const { fetchClients } = useClients()
    console.log(newNote);

    const toggleModal = () => {
        setIsModalOpen((prev) => !prev); // Toggle the modal's open/closed state
    };

    const handleSave = async () => {
        try {
            console.log('I AM HERE')
            if (Array.isArray(client.notes)) {
                client.notes = [...client.notes, {
                    id: client.notes.length + 1,
                    text: newNote,
                    date: new Date(),
                    author: user.userId
                }]
            }
            else {
                client.notes = [
                    {
                        id: 1,
                        text: newNote,
                        date: new Date(),
                        author: user.userId
                    }
                ]
            }
            await updateClient(client);
            await fetchClients();
            toggleModal()
        } catch (error) {
            console.log(error);
        };
    }
    return (
        <div className="w-full">
            <div className="flex justify-between w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 mb-4">
                <h1 className="text-2xl">Notes</h1>
                <p className="mt-2">
                    <EditIcon
                        height={25}
                        width={25}
                        onClick={toggleModal}
                        className="cursor-pointer"
                        data-testid='edit-client-icon'
                    />
                </p>
            </div>

            <div className="flex justify-center pt-[10%]">
                {client?.notes && client?.notes?.length > 0 ? (
                    client.notes.map((note) => (
                        <p key={note.id} className="text-lg mb-2">
                            <span className="font-bold"> {note.date.toLocaleString()} </span>-{note.text}
                        </p>
                    ))
                ) : (
                    <p>No Notes on File</p>
                )}
            </div>
            {
                <Modal
                    open={isModalOpen}
                    title="New Note"
                    content={
                        <>
                            <textarea className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary mb-4'
                                rows={6}
                                cols={50}
                                name="Notes"
                                placeholder="New Note..."
                                onChange={(event) => setNewNote(event.target.value)}>
                            </textarea>
                            <div className="flex justify-evenly">
                                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={toggleModal} styles="mr-4" />
                                <Button text='Save' onClick={handleSave} />
                            </div>
                        </>
                    }
                    onClose={toggleModal}
                />
            }
        </div>
    );
};

export default ClientNotesFeature;


/*
1. Need Notes header
2. Need pen Icon.
*/