import ClientVitals from './ClientVitals';
import './index.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const key = process.env.REACT_APP_STRIPE_ACCESS_KEY || '';
const stripePromise = loadStripe(key);


export const initializeApplication = () => (
    <Elements stripe={stripePromise}>
        <ClientVitals />
    </Elements>
)