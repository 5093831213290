import { 
    createContext,
    ReactNode,
    FunctionComponent,
    useState
} from "react";

export enum NotificationThemes {
    SUCCESS = 'success',
    FAIL = 'fail',
    NEUTRAL = 'neutral'
}

export type Notification = {
    message: string;
    theme: NotificationThemes;
}

export type DashboardContextType = {
    notification: Notification;
    updateNotification: (data: Notification) => void;
    clearNotification: () => void;
}

export const DashboardContext = createContext<DashboardContextType>({
    notification: {message: '', theme: NotificationThemes.NEUTRAL},
    updateNotification: () => { },
    clearNotification: () => {  },
});

interface IDashboardProviderProps {
    children: ReactNode
}

const blankNotification = {
    message: '',
    theme: NotificationThemes.NEUTRAL
}

const DashboardProvider: FunctionComponent<IDashboardProviderProps> = ({ children }) => {
    const [notification, setNotifcation] = useState<Notification>(blankNotification);

    const clearNotification = () => setTimeout(() => setNotifcation(blankNotification), 5000);
    const updateNotification = (data: Notification) => {
        setNotifcation(data);
        clearNotification();
    }

    const provider: DashboardContextType = {
        notification,
        updateNotification,
        clearNotification,
    }

    return (
        <DashboardContext.Provider value={provider}>
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider;