import { FunctionComponent, useState } from "react";
import Button from "../../Button";
import Modal from "../Modal";
import AddPaymentForm from "./AddPaymentForm";


const AddPaymentModal: FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(!open);

    return (
        <div>
            <Button text="Add" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Add Payment Method"
                content={<AddPaymentForm onCancel={toggleModal} />}
                onClose={toggleModal} 
                minHeight="200px"
            />
        </div>
    )
}

export default AddPaymentModal;