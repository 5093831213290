import { useState, useEffect } from 'react';
import { getAllExperts, getInstitutionExperts } from '../utils/ClientVitalsAPI';
import { useUser } from './useUser';
import { ExpertUser } from '../constants/experts';
import { UserTypes } from '../constants';

const useExperts = () => {
    const { user: { userId, userType } } = useUser();

    const [experts, setExperts] = useState<ExpertUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchExperts = async () => {
        if(!loading) setLoading(true);
        try {
            const isOwner = userType.toLowerCase() === UserTypes.OWNER.toLowerCase();
            const isCVA = userType.toLowerCase() === UserTypes.CLIENT_VITALS_ADMIN.toLowerCase()
            const fetchedExperts = (isOwner || isCVA) ? await getAllExperts(userId) : await getInstitutionExperts(userId);

            setExperts(fetchedExperts);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getExpertById = (id: string) => {
        return experts.find(({ userId }) => userId === id);
    }

    useEffect(() => {
        if(userId) fetchExperts();
    }, []);

    return { 
        experts, 
        loading, 
        error, 
        fetchExperts, 
        getExpertById,
    }
}

export default useExperts;