import { FunctionComponent, useEffect } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import useAdmins from "../../hooks/useAdmins";
import { clientTableActions } from "../../components/actionMenu/types";
import { AddAdminModal } from "../../components/modals";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { ACCESS_LEVEL } from "../../constants";
import Loading from "../../components/Loading";


const ClientVitalsAdmin: FunctionComponent = () => {
    const { user, loading } = useUser();
    const { userId } = user;    
    const { admins } = useAdmins(userId);
    const navigate = useNavigate();

    const headerList = ['checkbox', 'name', 'email', 'phone', 'actions']
    const formattedAdmins = admins?.map((admin) => {
        return {
            name: admin.userInfo.name,
            email: admin.userInfo.email,
            phone: admin.userInfo.phone
        }
    });

    useEffect(() => {
        if(user?.metadata?.accessLevel !== ACCESS_LEVEL.OWNER) navigate('/dashboard/clients')
    }, [])

    return (
        <DashboardPageContainer>
            <PageTitle title="ClientVitals Admins" />
            
            {
                loading ? <Loading />
                        : <Table
                            columnHeaders={headerList}
                            records={formattedAdmins}
                            tableActions={clientTableActions}
                            customButton={<AddAdminModal />}
                            linkBase="/dashboard/admins"
                        />
            }
        </DashboardPageContainer>
    )
}

export default ClientVitalsAdmin;