import { TableContext, TableContextType, } from "../../context/TableContext";
import { clientFilterCategories } from "../filterMenu/types";
import { FunctionComponent, ReactNode, useContext } from "react";
import TextInput from "../inputs/TextInput";
import Filters from "../filterMenu";

interface ITableToolbarProps {
    customButton?: ReactNode;
}

const TableToolbar: FunctionComponent<ITableToolbarProps> = ({ customButton }) => {
    const { setSearchTerm } = useContext(TableContext) as TableContextType;

    return (
        <div className="flex justify-between flex-row-reverse items-center w-[100%] my-2">
            {/* <Filters filterCategories={clientFilterCategories} /> */}

            <div className="flex items-center">
                {/* <TextInput 
                    id='search' 
                    placeholder="Search..." 
                    containerStyles="mr-2 min-w-[400px]"
                    onChange={(newTerm: string) => setSearchTerm(newTerm)}
                /> */}
                
                <div className="mb-3">
                {customButton && customButton}
                </div>
            </div>
        </div>
    )
}

export default TableToolbar;