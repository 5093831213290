import { useState, useEffect } from 'react';
import { getInstitutionClients, getExpertClients, getAllClients } from '../utils/ClientVitalsAPI';
import { ManagedClientUser } from '../constants/clients';
import { useUser } from './useUser';
import { UserTypes } from '../constants';

const useClients = () => {
    const { user } = useUser();
    const userType = user?.userType;
    const userId = user?.userId;

    const [clients, setClients] = useState<ManagedClientUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchClients = async () => {
        if (!loading) setLoading(true);

        const userTypeLower = userType.toLowerCase();
        
        const fetchFunction = (userTypeLower === UserTypes.OWNER.toLowerCase() || userTypeLower === UserTypes.CLIENT_VITALS_ADMIN.toLowerCase())  ? getAllClients
            : userTypeLower === UserTypes.FINANCIAL_INSTITUTION.toLowerCase() ? getInstitutionClients
                : getExpertClients
        try {
            const fetchedClients = await fetchFunction(userId);

            setClients(fetchedClients)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getClientById = (id: string) => {
        return clients.find(({ userId }) => userId === id);
    }

    useEffect(() => {
        if(userId) fetchClients();
    }, []);
    
    return { 
        clients, 
        loading, 
        error, 
        fetchClients ,
        getClientById
    }
}

export default useClients;