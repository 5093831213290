import { yupResolver } from "@hookform/resolvers/yup";
import { addCreditUser } from '../utils/SoftPullAPI';
import { useSearchParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ACCESS_LEVEL, UserTypes } from '../constants';
import { FunctionComponent, useState } from 'react';
import { registerAuthUser, markEmailAsVerified, disableUser, confirmUser } from '../utils/Cognito';
import { addNewCustomer, approveInstitutionApplication, newInstituteApplication } from '../utils/ClientVitalsAPI';
import RegisterInstitutionSchema from '../components/forms/ValidationScehma/RegisterInstitutionSchema';
import RegisterInstitutionForm from '../components/forms/RegisterInstitutionForm';
import RadialGradient from '../components/RadialGradient';
import convert from 'xml-js';
import Button from '../components/Button';
import axios from 'axios';

const Register: FunctionComponent = () => {
    const [params] = useSearchParams();
    const [terms, setTerms] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(RegisterInstitutionSchema)
    });

    const invitedBy = params.get('i');
    const accountType = watch('accountType');

    const getAccessLevel = (accountType: UserTypes) => {
        switch(accountType) {
            case UserTypes.FINANCIAL_INSTITUTION:
                return ACCESS_LEVEL.INSTITUTION;

            case UserTypes.FINANCIAL_EXPERT:
                return ACCESS_LEVEL.EXPERT;

            case UserTypes.CONSULT_REFERRALS:
                return ACCESS_LEVEL.REFERRAL;
            
            default:
                return ACCESS_LEVEL.CLIENT;
        }
    }

    const submit: SubmitHandler<any> = async (data) => {
        const { email, password } = data;
        try {
            if (!terms) return;
            const result = await registerAuthUser(email, password);

            await newInstituteApplication(data, result?.UserSub || '');
            
            if (invitedBy) {
                await confirmUser(email);
                await markEmailAsVerified(email);

                const institutionUser = {
                    userId: result?.UserSub || `${Math.random().toFixed(4)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`.replaceAll('.', 'e'),
                    userType: data.accountType,
                    userInfo: {
                        name: data.name,
                        address: data.address,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zip: data.zip,
                        phone: data.phone,
                        email: data.email,
                        ein: data.ein
                    },
                    metadata: {
                        approvedBy: invitedBy,
                        accessLevel: getAccessLevel(data.accountType)
                    }
                }

                const addResult = await approveInstitutionApplication(institutionUser)
                const ipResult = await axios.get('https://api.ipify.org?format=json');
                
                if(addResult.status === 201 || addResult.status === 200) {
                    const cbcResult = await addCreditUser({
                        uid: institutionUser.userId,
                        name: institutionUser.userInfo.name,
                        email: institutionUser.userInfo.email,
                        password: data.password,
                        ip: ipResult.data.ip
                    });

                    const result2 = convert.xml2js(cbcResult, { compact: true });
                    
                    //@ts-ignore
                    institutionUser.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];

                }
                const customer = await addNewCustomer(institutionUser);

                navigate(`/payment-information?ui=${institutionUser.userId}&si=${customer?.data.id}&name=${institutionUser.userInfo.name}`);
            }
            else {
                await disableUser(email);
                await markEmailAsVerified(email);
                
                navigate('/applied');
            }    
        } catch (error: any) {
            setError(error?.message || 'Failed To Register Account')
        }
    };

    return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className={`w-[700px] bg-white rounded-lg border-primary border-2 absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]`}>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Application for Account
                    </h4>
                </div>
                {
                    error && <p className='text-danger'>{error}</p>
                }

                <form className='w-full p-8 flex flex-col justify-center align-center'>
                    <RegisterInstitutionForm 
                        register={register} 
                        setTerms={(event) => setTerms(event?.target?.checked)} 
                        accountType={accountType || UserTypes.CONSULT_REFERRALS}
                        terms={terms}  
                        errors={errors} 
                    />

                    <Button
                        text={'Submit Application'}
                        styles='mt-3 w-[200px] self-center'
                        testId='submit-institution-register'
                        onClick={handleSubmit(submit)}
                    />
                </form>

                <div className='flex flex-col'>                    
                    <Link to='/login' className='text-primary self-center mb-4'>
                        Already Have Account?
                    </Link>
                </div>
            </div>  
        </div>
    )
}

export default Register;