import { FunctionComponent, ReactNode, useEffect, useState, useContext } from "react";
import { TableContext, TableContextType } from "../../context/TableContext";
import { TABLE_COLUMN_TYPE } from "../../constants";
import { MenuAction } from "../actionMenu/types";
import { Checkbox } from "../inputs";
import TableToolbar from "./TableToolbar";
import TableHeader from "./TableHeader";
import ActionMenu from "../actionMenu";
import Pagination from "../Pagination";
import TableRow from "./TableRow";

interface ITableBaseProps {
    columnHeaders: string[];
    records: Record<string, any>[];
    tableActions: MenuAction[];
    customButton?: ReactNode;
    linkBase?: string;
}

const TableBase: FunctionComponent<ITableBaseProps> = ({ columnHeaders, records, customButton, linkBase, tableActions }) => {
    const [recordsToRender, setRecordsToRender] = useState<Record<string, any>[]>(records);

    const {
        recordsShowing,
        paginationIndexAdjustment,
        activeFilters,
        searchTerm,
        isSelected,
        selectRecord,
        removeRecord,
        setTotalRecords
    } = useContext(TableContext) as TableContextType;
 
    const recordBuilder: (objToConvert: Record<string, any>) => (string | any)[] = (objToConvert) => {       
        const record: (string | any)[] = [];
        const selected = isSelected(objToConvert);
        const remove = () => removeRecord(objToConvert);
        const add = () => selectRecord(objToConvert);
      
        columnHeaders.forEach((key: string, index: number) => {
            switch (key) {
                case TABLE_COLUMN_TYPE.CHECKBOX:
                    record.push(
                        <Checkbox 
                            key={`${key}-${index}`}
                            labelText="" 
                            checked={selected}
                            onChange={selected ? remove : add} 
                        />
                    );
                    break;
                
                case TABLE_COLUMN_TYPE.ACTIONS:
                    record.push(<ActionMenu key={`${key}-${index}`} actions={tableActions} record={objToConvert} />);
                    break;
                
                default:
                    if (objToConvert[key] instanceof Date) {
                         record.push(objToConvert[key].toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }));
                    } else {
                         record.push(String(objToConvert[key]));
                    }
                   
                    break;
            }
        })

        return record
    }

    const applyFilter:(record: Record<string, any>) => boolean = (record) => {
        if (!activeFilters.length) return true;
        
        let discard = false; 
        
        activeFilters.forEach(filter => {
            columnHeaders.forEach(key => {
                if (record[key] === filter) discard = true;
            });
        })
        
        return discard;
    }

    const applySearchFilter: (record: Record<string, any>) => boolean = (record) => {
        if (!searchTerm.length) return true;
  
        let discard = false;

        columnHeaders.forEach(key => {
            const recordData = `${record[key]}`.toLowerCase();
            const searchTermFormatted = searchTerm.toLowerCase();
            if (recordData.includes(searchTermFormatted)) discard = true;
        });

        return discard;
    }

    useEffect(() => {
        setTotalRecords(records.length);
    }, [recordsToRender]);

    useEffect(() => {
        // const filteredRecords = records.filter(record => applyFilter(record));
        // const searchRecords = filteredRecords.filter(record => applySearchFilter(record));

        setTotalRecords(records.length);
        setRecordsToRender(records);
    }, []) 

    return (
        <>
            <TableToolbar customButton={customButton} />
            <table className="table-auto rounded-md w-[100%]">
                <TableHeader columnHeaders={columnHeaders} />
                <tbody>
                    {
                        records.slice(paginationIndexAdjustment, (recordsShowing + paginationIndexAdjustment))
                                       .map((record) => (
                            <TableRow 
                                rowSelected={isSelected(record)}
                                itemStrings={recordBuilder(record)} 
                                linkTo={linkBase ? `${linkBase}/${record.userId ? record.userId : record.applicationId}` : undefined}               
                                key={record.id}
                            />
                        ))
                    }
                </tbody>
            </table>
            <Pagination />
        </>
    );
}

export default TableBase;