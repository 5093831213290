import { FunctionComponent, useState } from 'react';
import RadialGradient from '../components/RadialGradient';
import ResetPasswordsForm from '../components/forms/ResetPasswordsForm';


const ForgotPassword: FunctionComponent = () => {
    const [error, setError] = useState<string | null>(null);

    return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className='w-[500px] bg-white rounded-lg border-primary border-2 absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]'>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Reset Password
                    </h4>
                </div>
                {
                    error && <p className='text-danger'>{error}</p>
                }

                <ResetPasswordsForm setError={setError} />
            </div>  
        </div>
    )
}

export default ForgotPassword;