import { FunctionComponent } from "react";
//@ts-ignore
import Pdf from '../../assets/documents/Contracts.pdf';
import _ from 'lodash';

interface ICheckboxProps {
    labelText: string;
    containerStyles?: string;
    labelStyles?: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    register?: Function;
    terms?: boolean;
}
                
const Checkbox: FunctionComponent<ICheckboxProps> = ({ register, labelText, containerStyles, labelStyles, checked, terms, onChange }) => (
    register ? (
        <div className={`${containerStyles} flex items-center justify-center cursor-pointer`}>
            <input type="checkbox" className="form-checkbox h-3 w-3 accent-primary cursor-pointer" checked={checked} value={labelText} {...register(_.camelCase(labelText?.replaceAll(' ', '')))} />
            <label className={`text-gray-900 font-medium ${labelStyles}`}>{labelText}</label>
        </div>
    ) : (
        <div className={`flex items-center justify-center cursor-pointer ${containerStyles}`}>
            <input type="checkbox" className="form-checkbox h-3 w-3 accent-primary cursor-pointer" checked={checked} value={labelText} onChange={onChange} />
                <label className={`text-gray-900 font-medium ${labelStyles}`}>{terms ? <a className="text-primary hover:underline" target="_blank" href={Pdf}>{labelText}</a> : labelText}</label>
        </div>
    )
);

export default Checkbox;