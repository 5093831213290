import { useUser } from "../../hooks/useUser";
import { useLocation } from "react-router-dom";
import { useDashboard } from "../../hooks/useDashboard";
import { ConfirmPullModal } from "../../components/modals";
import { NotificationThemes } from "../../context/DashboardContext";
import { analyzeReport, parseCreditReport } from "../../utils/TriMergeDifferential";
import { addPullApplication, chargeCustomer } from "../../utils/ClientVitalsAPI";
import { useState, FunctionComponent, useRef, useEffect } from "react";
import { ACCESS_LEVEL, PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../../constants/enums";
import { CreditUnions, firstPullSingleClientCredit, recallClientReport } from "../../utils/SoftPullAPI";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import useCreditApplications from "../../hooks/useCreditApplications";
import ReferClientModal from "../../components/modals/ReferClientModal";
import useCustomer from "../../hooks/useCustomer";
import ReportCard from "../../components/differentialReport/ReportCard";
import useClients from "../../hooks/useClients";
import useExperts from "../../hooks/useExperts";
import WideTile from "../../components/tiles/WideTile";
import PageTitle from "../../components/PageTitle";
import Loading from "../../components/Loading";
import convert from 'xml-js';

const ClientApplicationId: FunctionComponent = () => {
    const iframeRefReportA = useRef(null);
    const iframeRefReportB = useRef(null);
    const iframeRefReportC = useRef(null);

    const refs = [iframeRefReportA, iframeRefReportB, iframeRefReportC]

    const { user } = useUser();
    const { customer, paymentMethod } = useCustomer()
    const { updateNotification } = useDashboard();
    const { getClientById } = useClients();
    const { getExpertById } = useExperts();
    
    const { pathname } = useLocation();
    const pathArray = pathname.split('/');
    const clientId = pathArray[pathArray.length - 3]
    const { getApplication } = useCreditApplications(clientId);
    
    const appId = pathArray[pathArray.length - 1];
    const application = getApplication(appId);
    const expert = getExpertById(application?.metadata?.expert)

    const [creditReportType, setCreditReportType] = useState<CreditUnions>(CreditUnions.TRI_MERGE);
    const [creditReport, setCreditReport] = useState<any>(null);
    const [scoreCardReports, setScoreCardReports] = useState<Record<any, any>>([]);
    const [loading, setLoading] = useState(false);
    const [reportASummary, setReportASummary] = useState<Record<any, any> | null>(null);
    const [reportBSummary, setReportBSummary] = useState<Record<any, any> | null>(null);
    const [reportCSummary, setReportCSummary] = useState<Record<any, any> | null>(null);
    
    const productList = process.env.REACT_APP_ENV === 'prod' ? PRODUCT_LIST_PROD : PRODUCT_LIST_STAGE;

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportA.current) {
            setTimeout(() => analyzeReport(iframeRefReportA, setReportASummary), 4000);
        }
    }, [scoreCardReports, iframeRefReportA]);

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportB.current) {
            setTimeout(() => analyzeReport(iframeRefReportB, setReportBSummary), 4000);
        }
    }, [scoreCardReports, iframeRefReportB])

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportC.current) {
            setTimeout(() => analyzeReport(iframeRefReportC, setReportCSummary), 4000);
        }
    }, [scoreCardReports, iframeRefReportC])

    if (!application) return <Loading />
    
    const pullCredit = async (password: string) => {
        setLoading(true)

        try {
            const result = (!application.metadata.app_id && !application.metadata.credit_guid)
                ? await firstPullSingleClientCredit(
                application,
                //@ts-ignore
                user,
                password,
                application.applicationFormData.ssn,
                creditReportType
                ) : await recallClientReport(
                    application,
                    //@ts-ignore
                    user,
                    password,
                    application.applicationFormData.ssn,
                    creditReportType
                );
            
            const jsResult = convert.xml2js(result, { compact: true });
        
            if (!application.metadata.app_id || !application.metadata.credit_guid) {
                //@ts-ignore
                const app_id = jsResult["XML_INTERFACE"]["APP_ID"]["_text"].replace('{', '').replace('}', '');
          
                //@ts-ignore
                const BUREAU_TYPE = jsResult["XML_INTERFACE"]["CREDITREPORT"]["BUREAU_TYPE"];
                let credit_guid;
            
                if (Array.isArray(BUREAU_TYPE)) {
                    credit_guid = BUREAU_TYPE
                } else {
                    //@ts-ignore
                    credit_guid = BUREAU_TYPE["CREDIT_GUID"]["_text"];
                }
                application.metadata.app_id = app_id;
                application.metadata.credit_guid = credit_guid;
             
                await addPullApplication(application);
            }
            
            await parseCreditReport(jsResult, setScoreCardReports);
            setCreditReport(jsResult);
        } catch (error) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Pull Credit'
            })
        } finally {
            setLoading(false);
        }
    }

    const payAndPull = async (password: string) => {
        if(!customer || !paymentMethod) return;
        const productId = creditReportType === CreditUnions.TRI_MERGE ? productList.TRI_MERGE : productList.SINGLE_PULL
        try {
            const paymentResult = await chargeCustomer(
                customer?.id,
                paymentMethod?.id,
                productId
            );

            if(paymentResult.success) {
                await pullCredit(password);
            }
        } catch(error) {
            throw error;
        }
    }

    console.log({
        reportASummary,
        reportBSummary,
        reportCSummary
    })

    const pastDue= Number(reportASummary?.ninetyDays || 0) + Number(reportASummary?.sixtyDays || 0) + Number(reportASummary?.thirtyDays || 0)
                + Number(reportBSummary?.ninetyDays || 0) + Number(reportBSummary?.sixtyDays || 0) + Number(reportBSummary?.thirtyDays || 0)
                + Number(reportCSummary?.ninetyDays || 0) + Number(reportCSummary?.sixtyDays || 0) + Number(reportCSummary?.thirtyDays || 0)
    
    return (
        <DashboardPageContainer>
            <PageTitle
                title={`${application?.applicationFormData?.name}'s Pull Application`}
                ActionButton={
                    user.metadata.accessLevel !== ACCESS_LEVEL.REFERRAL ? () => (
                        <div className="flex justify-between">
                            <ConfirmPullModal
                                pullCredit={(user?.metadata?.accessLevel === ACCESS_LEVEL.OWNER || user?.metadata?.accessLevel === ACCESS_LEVEL.CVA) ? pullCredit : pullCredit}
                                creditReportType={creditReportType}
                                setCreditReportType={setCreditReportType}
                            />
                            <ReferClientModal existingClient={getClientById(clientId)} />
                        </div>
                    ) : () => <></>
                }
            />
            {
                loading ? <Loading />
                    : (creditReport && !loading) ? (
                        <>
                            <WideTile>
                                <div className="flex flex-col justify-between">
                                    <div className="w-[100%] flex flex-wrap align-start justify-between">
                                        <ReportCard>
                                            <h4 className="text-lg font-medium mb-6">Report Generation</h4>
                                            
                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Date & Time:</p>
                                                <p>{application.metadata.applicationSubmit}</p>
                                            </div>
                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Pull Type:</p>
                                                <p>{creditReportType === CreditUnions.TRI_MERGE ? 'Tri-Merge' : 'Single'}</p>
                                            </div>
                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Software User:</p>
                                                <p>{expert?.userInfo.name || user.userInfo.name}</p>
                                            </div>
                                        </ReportCard>

                                        <ReportCard>
                                            <h4 className="text-lg font-medium mb-6">Credit Score</h4>
                                            
                                            <div className="mb-2">
                                                <p>{application.applicationFormData.name} — ***-**-{application.applicationFormData.ssn.replaceAll('-','').substr(5)}</p>
                                            </div>
                                            
                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Score Threshold:</p>
                                                <p>680 (ClientVitals Default)</p>
                                            </div>

                                            <p className="font-semibold">Actual Score:</p>
                                            <div className="flex justify-between mb-2">    
                                                <div>
                                                    <p className={`${Number(reportASummary?.scores[0]?.score) < 680 ? 'text-danger' : isNaN(reportASummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">Experian:</span> {reportASummary?.scores[0]?.score || 'No Hit'}</p>
                                                    <p className="text-primary hover:underline"><a href={scoreCardReports[0].reportLink} target="_blank">View Report</a></p>
                                                </div>
                                                
                                                <div>
                                                    <p className={`${Number(reportBSummary?.scores[0]?.score) < 680 ? 'text-danger' : isNaN(reportBSummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">TransUnion:</span> {reportBSummary?.scores[0]?.score || 'No Hit'}</p>
                                                    <p className="text-primary hover:underline"><a href={scoreCardReports[1].reportLink} target="_blank">View Report</a></p>
                                                </div>
                                                
                                                <div>
                                                    <p className={`${Number(reportCSummary?.scores[0]?.score) < 680 ? 'text-danger' : isNaN(reportCSummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">Equifax:</span> {reportCSummary?.scores[0]?.score || 'No Hit'}</p>
                                                    <p className="text-primary hover:underline"><a href={scoreCardReports[2].reportLink} target="_blank">View Report</a></p>
                                                </div>
                                                
                                            </div>
                                        </ReportCard>

                                        <ReportCard>
                                            <h4 className="text-lg font-medium mb-2">Hardship Tracker</h4>

                                            <p className="mb-4">Late Payments (Anything Past 30 Days)</p>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Total Late Payment:</p>
                                                <p>{pastDue}</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Collection Items:</p>
                                                <p>{(Number(reportASummary?.collectionItems) || 0) + (Number(reportBSummary?.collectionItems) || 0) + (Number(reportCSummary?.collectionItems) || 0)}</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Judgements:</p>
                                                <p>{(Number(reportASummary?.judgements) || 0) + (Number(reportBSummary?.judgements) || 0) + (Number(reportCSummary?.judgements) || 0)}</p>
                                            </div>

                                            <p className="font-semibold mb-4">Utilization of Credit</p>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Experian:</p>
                                                <p>{100 - (Number(reportASummary?.availableCredit) || 0)}%</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">TransUnion:</p>
                                                <p>{100 - (Number(reportBSummary?.availableCredit) || 101)}%</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Equifax:</p>
                                                <p>{100 - (Number(reportCSummary?.availableCredit) || 0)}%</p>
                                            </div>
                                        </ReportCard>

                                        <ReportCard>
                                            <h4 className="text-md font-medium mb-2">Tri-Merge Differentials</h4>
                                            
                                            <h5 className="font-semibold mb-4">Report Summary</h5>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Experian:</p>
                                                <p>Amount Past Due: {reportASummary?.amountPastDue || 'No Hit'}</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">TransUnion:</p>
                                                <p>Amount Past Due: {reportBSummary?.amountPastDue || 'No Hit'}</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Equifax:</p>
                                                <p>Amount Past Due: {reportCSummary?.amountPastDue || 'No Hit'}</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Experian:</p>
                                                <p>90 days past due {reportASummary?.ninetyDays || 'an unknown number of '} times</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">TransUnion:</p>
                                                <p>90 days past due {reportBSummary?.ninetyDays || 'an unknown number of '} times</p>
                                            </div>

                                            <div className="flex justify-between mb-2">
                                                <p className="font-semibold">Equifax:</p>
                                                <p>90 days past due {reportCSummary?.ninetyDays || 'an unknown number of '} times</p>
                                            </div>
                                        </ReportCard>

                                        <ReportCard wide>
                                            <h4 className="text-lg text-danger font-medium mb-2">Warnings & Flags</h4>
                                            
                                            <ul>
                                                <li className="mb-2 font-semibold">Experian:</li>
                                                {
                                                    reportASummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                                                }

                                                <li className="mb-2 font-semibold">TransUnion:</li>
                                                {
                                                    reportBSummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                                                }

                                                <li className="mb-2 font-semibold">Equifax:</li>
                                                {
                                                    reportCSummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                                                }
                                            </ul>
                                        </ReportCard>
                                    </div>
                                </div>
                            </WideTile>

                            <div>
                                {
                                    scoreCardReports.map((scoreCard: Record<any, any>, index: number) => (
                                        <div className="mx-4">
                                            <iframe
                                                ref={refs[index]}
                                                srcDoc={scoreCard.report}
                                                style={{ width: '5px', height: '5px', border: 'none', opacity: 0 }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>

                            <WideTile>
                                <iframe
                                    srcDoc={creditReport["XML_INTERFACE"]["CREDITREPORT"]["REPORT"]["_cdata"]}
                                    style={{ width: '100%', height: '500px', border: 'none' }}
                                />
                            </WideTile>
                        </>
                ) : (
                    <WideTile>
                        <div>
                            <p><span className="font-semibold">Name:</span> {application.applicationFormData.name}</p>
                            <p><span className="font-semibold">Email:</span> {application.applicationFormData.email}</p>
                            <p><span className="font-semibold">Phone:</span> {application.applicationFormData.phone}</p>
                            <p><span className="font-semibold">Date of Birth:</span> {application.applicationFormData.dob}</p>
                            <p><span className="font-semibold">Address:</span> {application.applicationFormData.address}</p>
                            <p><span className="font-semibold">City:</span> {application.applicationFormData.city}</p>
                            <p><span className="font-semibold">State:</span> {application.applicationFormData.state}</p>
                            <p><span className="font-semibold">Zip:</span> {application.applicationFormData.zip}</p>
                            <p><span className="font-semibold">State:</span> {application.applicationFormData.state}</p>
                            <p className="text-success"><span className="font-semibold">Consent Given:</span> {application.applicationFormData.consent}</p>
                        </div>
                    </WideTile>
                )
            }

        </DashboardPageContainer>
    )
}
export default ClientApplicationId;